import {
  FETCH_SCRAPER_UPDATES,
  FETCH_USERS,
  FETCH_ADMIN_ARTICLES,
  SAVE_ARTICLE_BEGIN,
  SAVE_ARTICLE_SUCCESS,
  SAVE_ARTICLE_FAILURE,
} from '../types';

const INITIAL_STATE = {
  scraperUpdates: [],
  users: [],
  articles: [],
  savingArticle: false,
  newArticleId: '',
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_SCRAPER_UPDATES:
      return {
        ...state,
        scraperUpdates: payload,
      };
    case FETCH_USERS:
      return {
        ...state,
        users: payload,
      };
    case FETCH_ADMIN_ARTICLES:
      return {
        ...state,
        articles: payload,
      };
    case SAVE_ARTICLE_BEGIN:
      return {
        ...state,
        savingArticle: true,
      };
    case SAVE_ARTICLE_SUCCESS:
      return {
        ...state,
        savingArticle: false,
        newArticleId: payload,
      };
    case SAVE_ARTICLE_FAILURE:
      return {
        ...state,
        savingArticle: false,
      };
    default:
      return state;
  }
};
