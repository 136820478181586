import {
  FETCH_COUNTRY_BEGIN,
  FETCH_COUNTRY_SUCCESS,
  FETCH_COUNTRY_FAILURE,
  UPDATE_COUNTRY_BEGIN,
  UPDATE_COUNTRY_SUCCESS,
  TOGGLE_ACKNOWLEDGE_SCRAPER_BEGIN,
  TOGGLE_ACKNOWLEDGE_SCRAPER_SUCCESS,
  TOGGLE_ACKNOWLEDGE_SCRAPER_FAILURE,
} from '../types';

import moment from 'moment';

const INITIAL_STATE = {
  loading: true,
  loadingScraperToggle: '',
  id: '',
  name: '',
  active: false,
  lastUpdated: new moment(),
  scrapers: [],
  restrictions: { closed: [], restricted: [], open: [] },
  outbound: { closed: [], restricted: [], open: [] },
  covid: { cases: 0, deaths: 0, list: [] },
  credit: '',
  advisory: {},
  subscriptionEnabled: false,
  isSaving: false,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_COUNTRY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FETCH_COUNTRY_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
        restrictions: payload.restrictions || INITIAL_STATE.restrictions,
        outbound: payload.outbound || INITIAL_STATE.outbound,
        advisory: payload.advisory || INITIAL_STATE.advisory,
        covid: payload.covid || INITIAL_STATE.covid,
        credit: payload.credit || INITIAL_STATE.credit,
        subscriptionEnabled: payload.subscriptionEnabled || INITIAL_STATE.subscriptionEnabled,
        lastUpdated: payload.lastUpdated,
      };
    case FETCH_COUNTRY_FAILURE:
      return state;
    case UPDATE_COUNTRY_BEGIN:
      return {
        ...state,
        isSaving: true,
      };
    case UPDATE_COUNTRY_SUCCESS:
      return {
        ...state,
        isSaving: false,
      };
    case TOGGLE_ACKNOWLEDGE_SCRAPER_BEGIN:
      return {
        ...state,
        loadingScraperToggle: payload,
      };
    case TOGGLE_ACKNOWLEDGE_SCRAPER_SUCCESS:
      return {
        ...state,
        scrapers: payload,
        loadingScraperToggle: false,
      };
    case TOGGLE_ACKNOWLEDGE_SCRAPER_FAILURE:
      return {
        ...state,
        loadingScraperToggle: '',
      };
    default:
      return state;
  }
};
