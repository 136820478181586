import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import Primary from '../templates/Primary';
import ArticleImage from './ArticleImage';
import Byline from './Byline';
import Sidebar from './Sidebar';

import './article.scss';

const Article = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { article: articleId } = useParams();
  const article = useSelector((state) => state.articles.articles.filter(({ id }) => id === articleId)[0]);
  if (!article) {
    return <div />;
  }

  console.log(article);

  return (
    <Primary hero={article.image}>
      <div className="article-content-container">
        <div className="article-content">
          <h1>{article.header}</h1>
          <Byline published={moment(article.published.seconds * 1000)} />
          {article.ingress.length > 0 && <h4>{article.ingress}</h4>}
          {article.blocks.map((block, index) => {
            if (block.type === 'text') {
              return (
                <p
                  key={`block-${index}`}
                  dangerouslySetInnerHTML={{ __html: block.text.replace(/(?:\r\n|\r|\n)/g, '<br>') }}
                />
              );
            } else if (block.type === 'subheader') {
              return (
                <h3
                  key={`block-${index}`}
                  dangerouslySetInnerHTML={{ __html: block.text.replace(/(?:\r\n|\r|\n)/g, '<br>') }}
                />
              );
            } else if (block.type === 'image') {
              return <ArticleImage key={`block-${index}`} image={block.image} caption={block.caption} />;
            }
          })}
        </div>
        <a className="article-ad-desktop" href="https://maleehighlands.com/" target="_blank">
          <img src="/ads/malee_highlands.jpg" alt="" />
        </a>
        <a className="article-ad-mobile" href="https://maleehighlands.com/" target="_blank">
          <img src="/ads/malee_highlands_square.jpg" alt="" />
        </a>
        {/* <Sidebar /> */}
      </div>
    </Primary>
  );
};

export default Article;
