import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Header, Button } from 'semantic-ui-react';
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/functions';

import { fetchUsers } from '../../../store/actions';

const Users = (props) => {
  const dispatch = useDispatch();
  const {
    admin: { users },
    countries: { list },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const [deleteWarning, setDeleteWarning] = useState('');
  const [deleting, setDeleting] = useState(false);

  return (
    <>
      <Header size="large">Users</Header>
      <Table celled padded>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Subscribed to</Table.HeaderCell>
            <Table.HeaderCell>Subscription status</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {users.map(({ id, name, email, expires, subscriptions }) => (
            <Table.Row key={`user-${id}`}>
              <Table.Cell singleLine>
                <Header>{name}</Header>
                <a style={{ color: '#000' }} href={`mailto:${email}`}>
                  {email}
                </a>
                <br />
                {id}
              </Table.Cell>
              <Table.Cell>
                {subscriptions && subscriptions.reduce((acc, next) => `${acc}, ${list[next].name}`, '').slice(2)}
              </Table.Cell>
              <Table.Cell>
                {expires && moment(expires.seconds * 1000).isAfter(moment()) ? (
                  <>
                    <b>Active</b>
                    <br />
                    Expires: {moment(expires.seconds * 1000).format('YYYY-MM-DD HH:mm')}
                  </>
                ) : (
                  <b>Inactive</b>
                )}
              </Table.Cell>
              <Table.Cell>
                {deleteWarning === id ? (
                  <>
                    <Button
                      circular
                      icon="remove"
                      color="grey"
                      onClick={() => setDeleteWarning('')}
                      disabled={deleting}
                    />
                    <br />
                    <Button
                      circular
                      icon="trash"
                      color="red"
                      disabled={deleting}
                      onClick={async () => {
                        setDeleting(true);
                        await firebase.functions().httpsCallable('admin-deleteUser')({ id });

                        setDeleting(false);
                        dispatch(fetchUsers());
                      }}
                    />
                  </>
                ) : (
                  <Button circular icon="trash" color="red" onClick={() => setDeleteWarning(id)} />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default Users;
