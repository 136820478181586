import React from 'react';
import moment from 'moment';

import Translate from '../utilities/Translate';

import './byline.scss';

const Byline = ({ published }) => {
  return (
    <div className="byline">
      <img src="/square_logo.png" alt="" />
      <div>
        <h4>
          <Translate>Editorial team</Translate>
        </h4>
        <p>
          <Translate>Published</Translate> {published.fromNow()}
        </p>
      </div>
    </div>
  );
};

export default Byline;
