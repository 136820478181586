import { SET_LANGUAGE } from '../types';
import { fetchArticles } from './articles';

export const setLanguage = (language) => {
  return (dispatch) => {
    dispatch(fetchArticles(language));

    dispatch({
      type: SET_LANGUAGE,
      payload: language,
    });
  };
};
