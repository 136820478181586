import React from 'react';

import './articleImage.scss';

const ArticleImage = ({ image, caption }) => {
  return (
    <div className="article-image">
      <img src={image} />
      {Boolean(caption) && <p>{caption}</p>}
    </div>
  );
};

export default ArticleImage;
