import React from 'react';
import { useSelector } from 'react-redux';
import { useScrollYPosition } from 'react-use-scroll-position';

import Translate from '../utilities/Translate';
import SelectDestinationCountry from './SelectDestinationCountry';

import './hero.scss';

const Hero = ({ country }) => {
  const scrollY = useScrollYPosition();
  const {
    country: { credit },
  } = useSelector((state) => state);

  return (
    <div
      className="hero-container"
      style={{
        backgroundImage: `url(/${country ? `country_hero/${country}` : 'hero_background'}.jpg)`,
        backgroundSize: `${120 + scrollY / 100}%`,
      }}
    >
      <div className="hero-content">
        <h1>
          {country ? <div>&nbsp;</div> : <Translate>Get the latest updates regarding travel restrictions!</Translate>}
        </h1>
        <div className="hro-search-destinateion">{!Boolean(country) && <SelectDestinationCountry />}</div>
      </div>
      {country && credit && (
        <div className="hero-footer">
          <span dangerouslySetInnerHTML={{ __html: credit }} />
        </div>
      )}
    </div>
  );
};

export default Hero;
