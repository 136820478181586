export const SIGN_IN_EMAIL_SENT = 'SIGN_IN_EMAIL_SENT';
export const SIGN_IN_BEGIN = 'SIGN_IN_BEGIN';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';

export const SIGN_OUT_BEGIN = 'SIGN_OUT_BEGIN';
export const SIGN_OUT_FAILURE = 'SIGN_OUT_FAILURE';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';

export const FETCH_USER_BEGIN = 'FETCH_USER_BEGIN';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const TOGGLE_COUNTRY_NOTIFICATIONS_BEGIN = 'TOGGLE_COUNTRY_NOTIFICATIONS_BEGIN';
export const TOGGLE_COUNTRY_NOTIFICATIONS_SUCCESS = 'TOGGLE_COUNTRY_NOTIFICATIONS_SUCCESS';
export const TOGGLE_COUNTRY_NOTIFICATIONS_FAILURE = 'TOGGLE_COUNTRY_NOTIFICATIONS_FAILURE';

export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS';
export const FETCH_COUNTRY_UPDATES_SUCCESS = 'FETCH_COUNTRY_UPDATES_SUCCESS';

export const SET_LANGUAGE = 'SET_LANGUAGE';

export const FETCH_COUNTRY_BEGIN = 'FETCH_COUNTRY_BEGIN';
export const FETCH_COUNTRY_SUCCESS = 'FETCH_COUNTRY_SUCCESS';
export const FETCH_COUNTRY_FAILURE = 'FETCH_COUNTRY_FAILURE';
export const UPDATE_COUNTRY_BEGIN = 'UPDATE_COUNTRY_BEGIN';
export const UPDATE_COUNTRY_SUCCESS = 'UPDATE_COUNTRY_SUCCESS';
export const UPDATE_COUNTRY_FAILURE = 'UPDATE_COUNTRY_FAILURE';
export const TOGGLE_ACKNOWLEDGE_SCRAPER_BEGIN = 'TOGGLE_ACKNOWLEDGE_SCRAPER_BEGIN';
export const TOGGLE_ACKNOWLEDGE_SCRAPER_SUCCESS = 'TOGGLE_ACKNOWLEDGE_SCRAPER_SUCCESS';
export const TOGGLE_ACKNOWLEDGE_SCRAPER_FAILURE = 'TOGGLE_ACKNOWLEDGE_SCRAPER_FAILURE';

export const FETCH_ARTICLES = 'FETCH_ARTICLES';

export const SET_COOKIE_CONSENT = 'SET_COOKIE_CONSENT';
export const SET_FIREBASE_INITIALIZED = 'SET_FIREBASE_INITIALIZED';
export const HIDE_PREMIUM_BANNER = 'HIDE_PREMIUM_BANNER';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const SET_DEPARTURE_COUNTRY = 'SET_DEPARTURE_COUNTRY';
export const SET_DESTINATION_COUNTRY = 'SET_DESTINATION_COUNTRY';
export const HOVER_DESTINATION_COUNTRY = 'HOVER_DESTINATION_COUNTRY';
export const SET_DEPARTURE_DISPLAY = 'SET_DEPARTURE_DISPLAY';
export const SET_DESTINATION_DISPLAY = 'SET_DESTINATION_DISPLAY';
export const SET_DEPARTURE_FILTERS = 'SET_DEPARTURE_FILTERS';
export const SET_DESTINATION_FILTERS = 'SET_DESTINATION_FILTERS';

export const FETCH_SCRAPER_UPDATES = 'FETCH_SCRAPER_UPDATES';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_ADMIN_ARTICLES = 'FETCH_ADMIN_ARTICLES';
export const SAVE_ARTICLE_BEGIN = 'SAVE_ARTICLE_BEGIN';
export const SAVE_ARTICLE_SUCCESS = 'SAVE_ARTICLE_SUCCESS';
export const SAVE_ARTICLE_FAILURE = 'SAVE_ARTICLE_FAILURE';
