import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Checkbox from '../utilities/checkbox/Checkbox';
import PrimaryButton from '../utilities/buttons/PrimaryButton';
import Translate from '../utilities/Translate';
import moment from 'moment';

import Primary from '../templates/Primary';
import { signOut, toggleCountrySubscription } from '../../store/actions';

import './profile.scss';

const Profile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const subscriptionEnabledCountries = useSelector((state) =>
    Object.entries(state.countries.list)
      .filter(([_, { active, subscriptionEnabled }]) => active && subscriptionEnabled)
      .map(([id, { name, continent }]) => ({ id, name, continent }))
      .sort(({ name: a }, { name: b }) => (a < b ? -1 : 1))
      .reduce(
        (acc, { id, name, continent }) =>
          acc[continent]
            ? { ...acc, [continent]: [...acc[continent], { id, name }] }
            : { ...acc, [continent]: [{ id, name }] },
        {}
      )
  );

  console.log(subscriptionEnabledCountries);

  const {
    user: { user },
  } = useSelector((state) => state);

  if (!user.expires) {
    return <div />;
  }

  const { subscriptions, expires } = user;

  return (
    <Primary title="Profile" hero="/profile_hero.jpg">
      <div className="profile-container">
        <div>
          <h2>Subscription</h2>
          <br />
          Status:{' '}
          {moment(expires.seconds * 1000).isAfter(moment())
            ? `Expires: ${moment(expires.seconds * 1000).format('D MMM YYYY')}`
            : 'Inactive'}
          <div className="profile-cancel-signout">
            <PrimaryButton background="#ff0202" center>
              <Translate>Cancel subscription</Translate>
            </PrimaryButton>
            <PrimaryButton
              center
              onClick={() => {
                dispatch(signOut());
                history.push(`/`);
              }}
            >
              Log out
            </PrimaryButton>
          </div>
        </div>
        <div className="profile-subscriptions">
          <h2>
            <Translate>Subscriptions</Translate>
          </h2>
          {Object.entries(subscriptionEnabledCountries)
            .sort(([a], [b]) => (a < b ? -1 : 1))
            .map(([continent, countries]) => (
              <div>
                <h4>{continent}</h4>
                {countries.map(({ id, name }) => (
                  <Checkbox
                    key={`subscribed-to-${id}`}
                    onChange={() => dispatch(toggleCountrySubscription(id))}
                    checked={subscriptions && subscriptions.includes(id)}
                  >
                    {name}
                  </Checkbox>
                ))}
              </div>
            ))}
        </div>
      </div>
    </Primary>
  );
};

export default Profile;
