import {
  SET_COOKIE_CONSENT,
  SET_DEPARTURE_COUNTRY,
  SET_DESTINATION_COUNTRY,
  HOVER_DESTINATION_COUNTRY,
  SET_FIREBASE_INITIALIZED,
  HIDE_PREMIUM_BANNER,
  TOGGLE_MENU,
  SET_DEPARTURE_DISPLAY,
  SET_DESTINATION_DISPLAY,
  SET_DEPARTURE_FILTERS,
  SET_DESTINATION_FILTERS,
  FETCH_ARTICLES,
} from '../types';

export const setDepartureCountry = (countryCode) => {
  localStorage.setItem('country', countryCode);

  return {
    type: SET_DEPARTURE_COUNTRY,
    payload: countryCode,
  };
};

export const setHoverDestinationCountry = (countryCode) => {
  return {
    type: HOVER_DESTINATION_COUNTRY,
    payload: countryCode,
  };
};

export const hidePremiumBanner = () => {
  return {
    type: HIDE_PREMIUM_BANNER,
  };
};

export const setDestinationCountry = (countryCode) => {
  return {
    type: SET_DESTINATION_COUNTRY,
    payload: countryCode,
  };
};

export const setCookieConsent = (acceptedCookies) => {
  localStorage.setItem('hasSeenCookieConsentModal', 'true');
  localStorage.setItem('necessary', String(acceptedCookies.necessary));
  localStorage.setItem('analytics', String(acceptedCookies.analytics));

  return {
    type: SET_COOKIE_CONSENT,
    payload: acceptedCookies,
  };
};

export const setFirebaseInitialized = () => {
  return {
    type: SET_FIREBASE_INITIALIZED,
  };
};

export const toggleMenu = () => {
  return {
    type: TOGGLE_MENU,
  };
};

export const setDepartureDisplay = (display) => {
  return {
    type: SET_DEPARTURE_DISPLAY,
    payload: display,
  };
};

export const setDestinationDisplay = (display) => {
  return {
    type: SET_DESTINATION_DISPLAY,
    payload: display,
  };
};

export const setDepartureFilters = (filters) => {
  return {
    type: SET_DEPARTURE_FILTERS,
    payload: filters,
  };
};

export const setDestinationFilters = (filters) => {
  return {
    type: SET_DESTINATION_FILTERS,
    payload: filters,
  };
};
