import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import Primary from '../templates/Primary';
import PremiumFeatures from './PremiumFeatures';
import SignUpForm from './SignUpForm';
import useTranslation from '../utilities/useTranslation';

import './signUp.scss';

const SignUp = () => {
  // const stripePromise = loadStripe('pk_test_yrL8IYdp07H8IooQRqxIf9Pw'); // test
  const stripePromise = loadStripe('pk_live_sucecaxiKU71MrxU1PVviIo0'); // live
  const translation = useTranslation();

  return (
    <Primary title={translation('Sign up for Premium')} hero="/news_hero_background_original.jpg">
      <div className="sign-up-content-container">
        <PremiumFeatures />
        <Elements stripe={stripePromise}>
          <SignUpForm />
        </Elements>
      </div>
    </Primary>
  );
};

export default SignUp;
