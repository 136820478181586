import {
  FETCH_COUNTRY_BEGIN,
  FETCH_COUNTRY_SUCCESS,
  FETCH_COUNTRY_FAILURE,
  UPDATE_COUNTRY_BEGIN,
  UPDATE_COUNTRY_SUCCESS,
  UPDATE_COUNTRY_FAILURE,
  TOGGLE_ACKNOWLEDGE_SCRAPER_BEGIN,
  TOGGLE_ACKNOWLEDGE_SCRAPER_SUCCESS,
  TOGGLE_ACKNOWLEDGE_SCRAPER_FAILURE,
} from '../types';

import moment from 'moment';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { fetchScraperUpdates } from './admin';
import { fetchCountries } from './countries';

export const fetchCountry = (id) => {
  return async (dispatch, getState) => {
    dispatch({
      type: FETCH_COUNTRY_BEGIN,
    });

    const countryDoc = await firebase.firestore().collection('countries').doc(id).get();

    if (countryDoc.exists) {
      const country = countryDoc.data();
      country.id = id;

      if (getState().user.user.isAdmin) {
        country.scrapers = [];

        const scrapersSnapshot = await firebase
          .firestore()
          .collection('countries')
          .doc(id)
          .collection('scrapers')
          .get();

        country.scrapers = scrapersSnapshot.docs.map((scraper) => ({ id: scraper.id, ...scraper.data() }));
      }

      if (country.lastUpdated) {
        country.lastUpdated(new moment(country.lastUpdated.seconds * 1000));
      } else {
        country.lastUpdated = new moment();
      }

      dispatch({
        type: FETCH_COUNTRY_SUCCESS,
        payload: country,
      });
    } else {
      dispatch({
        type: FETCH_COUNTRY_FAILURE,
      });
    }
  };
};

export const updateCountry = (id, data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_COUNTRY_BEGIN,
    });

    try {
      await firebase.firestore().collection('countries').doc(id).update(data);

      const statusUpdates = Object.entries(data.restrictions)
        .map(([status, countries]) =>
          countries.map(
            (country) =>
              new Promise(async (resolve) => {
                try {
                  const countryDoc = await firebase.firestore().collection('countries').doc(country).get();
                  if (!countryDoc.exists) {
                    console.log(country);
                  }
                  const outbound = countryDoc.data().outbound || { closed: [], restricted: [], open: [] };
                  ['closed', 'restricted', 'open'].forEach((status) => {
                    outbound[status] = outbound[status].filter((c) => !c.includes(id));
                  });

                  outbound[status].push(id);
                  await firebase.firestore().collection('countries').doc(country).update({ outbound });
                  resolve({ country, outbound });
                } catch (error) {
                  console.log(error);
                  resolve({ country, outbound: { closed: [], restricted: [], open: [] } });
                }
              })
          )
        )
        .reduce((acc, next) => [...acc, ...next], []);

      const countries = (await firebase.firestore().collection('directory').doc('countries').get()).data();
      const countryListUpdate = await firebase
        .firestore()
        .collection('directory')
        .doc('countries')
        .set({ ...countries, [id]: { ...countries[id], active: data.active } });

      await Promise.all([...statusUpdates, countryListUpdate]);

      await firebase
        .firestore()
        .collection('directory')
        .doc('countries')
        .update({ [id]: { ...getState().countries.list[id], active: data.active } });
      dispatch(fetchCountries());

      dispatch({
        type: UPDATE_COUNTRY_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_COUNTRY_FAILURE,
      });
    }
  };
};

export const toggleAcknowledgeScraper = (scraperId) => {
  return async (dispatch, getState) => {
    dispatch({
      type: TOGGLE_ACKNOWLEDGE_SCRAPER_BEGIN,
      payload: scraperId,
    });

    const { scrapers } = getState().country;
    let acknowledged;
    const updatedScrapers = scrapers.map((scraper) => {
      if (scraper.id === scraperId) {
        acknowledged = !scraper.acknowledged;
        return { ...scraper, acknowledged };
      }
      return scraper;
    });

    try {
      await firebase
        .firestore()
        .collection('countries')
        .doc(getState().country.id)
        .collection('scrapers')
        .doc(scraperId)
        .update({ acknowledged });

      dispatch(fetchScraperUpdates());

      dispatch({
        type: TOGGLE_ACKNOWLEDGE_SCRAPER_SUCCESS,
        payload: updatedScrapers,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: TOGGLE_ACKNOWLEDGE_SCRAPER_FAILURE,
      });
    }
  };
};
