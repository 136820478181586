import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useLocation } from 'react-router-dom';
import Header from './header/Header';
import TopMenu from './header/TopMenu';
import Footer from './footer/Footer';
import Home from './home/Home';
import Country from './country/Country';
import Editorial from './editorial/Editorial';
import Article from './editorial/Article';
import CreditCards from './creditcards/CreditCards';
import Insurance from './insurance/Insurance';
import About from './about/About';
import Profile from './profile/Profile';
import SignUp from './premium/SignUp';
import SignUpComplete from './premium/SignUpComplete';
import PremiumBanner from './home/PremiumBanner';

import CookieConsentModal from './utilities/CookieConsentModal';

const App = () => {
  const location = useLocation();
  const {
    app: { menuOpen, premiumBannerVisible },
    user: { signingIn },
  } = useSelector((state) => state);

  return (
    <div className="app-container">
      <div className="app-content">
        <Header />
        <TopMenu />
        {premiumBannerVisible &&
          !signingIn &&
          !menuOpen &&
          !['/signup', '/signupcomplete'].includes(location.pathname) && <PremiumBanner />}
        <div>
          <CookieConsentModal />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/country/:country" component={Home} />

            <Route path="/signup" component={SignUp} />
            <Route exact path="/articles" component={Editorial} />
            <Route path="/articles/:article" component={Article} />
            <Route path="/creditcards" component={CreditCards} />
            <Route path="/insurance" component={Insurance} />
            <Route path="/about" component={About} />
            <Route path="/profile" component={Profile} />
            <Route path="/signupcomplete" component={SignUpComplete} />
          </Switch>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default App;
