import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Icon } from 'semantic-ui-react';

import Translate from '../../utilities/Translate';

import './updates.scss';

const Updates = () => {
  const { list, updates } = useSelector((state) => state.countries);

  return (
    <div className="updates-outer-container">
      <div className="updates-container">
        <div className="updates-header">
          <img src="/warning_sign.png" alt="" />
          <Translate>Latest updates</Translate>
        </div>
        <div className="updates-content">
          {updates.map(({ updated, url, country, name }, index) => (
            <div key={`updates-item-${index}`} className="updates-item">
              <div className="updates-item-date">
                <Icon name="clock outline" />
                {moment(updated.seconds * 1000).format('D MMM YYYY – HH:mm')}
              </div>
              <div className="updates-item-title">
                <img src={`/flags/${country.toUpperCase()}.png`} alt="" />
                {list[country].name}
              </div>
              <div className="updates-item-text">{name}</div>
              <div className="updates-item-read-more">
                <a href={url}>
                  <Translate>Read more</Translate>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Updates;
