import React from 'react';
import { useSelector } from 'react-redux';
import AnimatedNumber from 'react-animated-number';

import Translate from '../Translate';

import './mapInformation.scss';

const MapInformation = () => {
  const {
    country: { outbound },
  } = useSelector((state) => state);

  return (
    <div className="map-information">
      <div className="map-information-numbers">
        <div className="map-information-open">
          <h1>
            <AnimatedNumber component="span" value={outbound.open.length} duration={200} stepPrecision={0} />
          </h1>
          <Translate>Open now</Translate>
        </div>
        <div className="map-information-restricted">
          <h1>
            <AnimatedNumber component="span" value={outbound.restricted.length} duration={200} stepPrecision={0} />
          </h1>
          <Translate>Restricted</Translate>
        </div>
        <div className="map-information-closed">
          <h1>
            <AnimatedNumber component="span" value={outbound.closed.length} duration={200} stepPrecision={0} />
          </h1>
          <Translate>Closed</Translate>
        </div>
      </div>
      <div className="map-information-disclaimer">
        <Translate>Country travel information</Translate>
      </div>
    </div>
  );
};

export default MapInformation;
