import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Translate from '../Translate';
import useTranslation from '../useTranslation';
import MapLegend from '../mapLegend/MapLegend';

import './countryRestrictionsList.scss';

const CountryRestrictionsList = ({ restrictions }) => {
  const {
    countries: { list },
    country: { name },
  } = useSelector((state) => state);
  const translation = useTranslation();

  return (
    <div className="country-restrictions-list">
      {Object.values(restrictions).reduce((acc, next) => next.length > 0 || acc, false) ? (
        <>
          <MapLegend />
          <div className="country-restrictions-list-columns">
            {restrictions.open
              .filter((country) => list[country])
              .sort((a, b) => (translation(list[a].name) < translation(list[b].name) ? -1 : 1))
              .map((country, index) => (
                <div key={`open-${index}`}>
                  <div className="map-legend-info-open" />
                  <Link to={`/country/${country}`}>
                    <Translate>{list[country].name}</Translate>
                  </Link>
                </div>
              ))}
            {restrictions.restricted
              .filter((country) => list[country])
              .sort((a, b) => (translation(list[a].name) < translation(list[b].name) ? -1 : 1))
              .map((country, index) => (
                <div key={`restricted-${index}`}>
                  <div className="map-legend-info-restricted" />
                  <Link to={`/country/${country}`}>
                    <Translate>{list[country].name}</Translate>
                  </Link>
                </div>
              ))}
            {restrictions.closed
              .filter((country) => list[country])
              .sort((a, b) => (translation(list[a].name) < translation(list[b].name) ? -1 : 1))
              .map((country, index) => (
                <div key={`closed-${index}`}>
                  <div className="map-legend-info-closed" />
                  <Link to={`/country/${country}`}>
                    <Translate>{list[country].name}</Translate>
                  </Link>
                </div>
              ))}
          </div>
        </>
      ) : (
        <div className="country-restrictions-list-empty">
          <Translate>Whoopsi, we haven't had time to add restrictions for</Translate> {name} <Translate>yet.</Translate>
        </div>
      )}
    </div>
  );
};

export default CountryRestrictionsList;
