import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Segment, Container, Grid, Header, List, Divider, Image } from 'semantic-ui-react';

const AdminFooter = (props) => {
  const { list } = useSelector((state) => state.countries);
  const filteredCountries = Object.entries(list)
    .filter(([_, { continent }]) => ['Europe', 'Asia', 'South America', 'Africa'].includes(continent))
    .reduce(
      (acc, [key, { name, continent }]) => {
        switch (continent) {
          case 'Europe':
            return {
              ...acc,
              europe: [...acc.europe, [key, name]],
            };
          case 'Asia':
            return {
              ...acc,
              asia: [...acc.asia, [key, name]],
            };
          case 'South America':
            return {
              ...acc,
              southAmerica: [...acc.southAmerica, [key, name]],
            };
          case 'Africa':
            return {
              ...acc,
              africa: [...acc.africa, [key, name]],
            };
          default:
            return acc;
        }
      },
      { europe: [], asia: [], southAmerica: [], africa: [] }
    );

  return (
    <Segment inverted vertical style={{ margin: '5em 0em 0em', padding: '5em 0em' }}>
      <Container textAlign="center">
        <Grid divided inverted stackable>
          <Grid.Column width={3}>
            <Header inverted content="Europe" />
            <List link inverted>
              {filteredCountries.europe
                .sort(([_, a], [__, b]) => (a < b ? -1 : 1))
                .map(([key, name]) => (
                  <List.Item key={`footer-country-${key}`} as={Link} to={`/admin/countries/${key}`}>
                    {name}
                  </List.Item>
                ))}
            </List>
          </Grid.Column>
          <Grid.Column width={3}>
            <Header inverted content="Asia" />
            <List link inverted>
              {filteredCountries.asia
                .sort(([_, a], [__, b]) => (a < b ? -1 : 1))
                .map(([key, name]) => (
                  <List.Item key={`footer-country-${key}`} as={Link} to={`/admin/countries/${key}`}>
                    {name}
                  </List.Item>
                ))}
            </List>
          </Grid.Column>
          <Grid.Column width={3}>
            <Header inverted content="Africa" />
            <List link inverted>
              {filteredCountries.africa
                .sort(([_, a], [__, b]) => (a < b ? -1 : 1))
                .map(([key, name]) => (
                  <List.Item key={`footer-country-${key}`} as={Link} to={`/admin/countries/${key}`}>
                    {name}
                  </List.Item>
                ))}
            </List>
          </Grid.Column>
          <Grid.Column width={3}>
            <Header inverted as={Link} to="/admin/countries/us" size="medium" content="USA" />
            <br />
            <br />
            <Header inverted as={Link} to="/admin/countries/ca" size="medium" content="Canada" />
            <br />
            <br />
            <Header inverted as={Link} to="/admin/countries/au" size="medium" content="Australia" />
            <br />
            <br />
            <Header inverted as={Link} to="/admin/countries/nz" size="medium" content="New Zealand" />
            <br />
            <br />
            <Header inverted content="South America" />
            <List link inverted>
              {filteredCountries.southAmerica
                .sort(([_, a], [__, b]) => (a < b ? -1 : 1))
                .map(([key, name]) => (
                  <List.Item key={`footer-country-${key}`} as={Link} to={`/admin/countries/${key}`}>
                    {name}
                  </List.Item>
                ))}
            </List>
          </Grid.Column>
          <Grid.Column width={4}>
            <Header inverted as="h4" content="TRAVEL.UPDATE" />
            <p>All right reserved &copy; 2020</p>
          </Grid.Column>
        </Grid>

        <Divider inverted section />
        <Image centered size="large" src="/logo.png" />
      </Container>
    </Segment>
  );
};

export default AdminFooter;
