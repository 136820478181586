import {
  SET_COOKIE_CONSENT,
  SET_FIREBASE_INITIALIZED,
  HIDE_PREMIUM_BANNER,
  SET_DEPARTURE_COUNTRY,
  HOVER_DESTINATION_COUNTRY,
  SET_DESTINATION_COUNTRY,
  TOGGLE_MENU,
  FETCH_USER_SUCCESS,
  SET_DEPARTURE_DISPLAY,
  SET_DESTINATION_DISPLAY,
  SET_DEPARTURE_FILTERS,
  SET_DESTINATION_FILTERS,
} from '../types';

const INITIAL_STATE = {
  departureCountry: '',
  hasSeenCookieConsentModal: false,
  firebaseInitialized: false,
  destinationCountry: '',
  hoverDestinationCountry: '',
  cookieConsent: {},
  premiumBannerVisible: true,
  menuOpen: false,
  departureDisplay: 'map',
  destinationDisplay: 'map',
  departureFilters: ['open', 'restricted', 'closed'],
  destinationFilters: ['open', 'restricted', 'closed'],
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_DEPARTURE_COUNTRY:
      return {
        ...state,
        departureCountry: payload,
      };
    case SET_COOKIE_CONSENT:
      return {
        ...state,
        hasSeenCookieConsentModal: true,
        cookieConsent: payload,
      };
    case SET_FIREBASE_INITIALIZED:
      return {
        ...state,
        firebaseInitialized: true,
      };
    case HIDE_PREMIUM_BANNER:
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        premiumBannerVisible: false,
      };
    case HOVER_DESTINATION_COUNTRY:
      return {
        ...state,
        hoverDestinationCountry: payload,
      };
    case SET_DESTINATION_COUNTRY:
      return {
        ...state,
        destinationCountry: payload,
      };
    case TOGGLE_MENU:
      return {
        ...state,
        menuOpen: !state.menuOpen,
      };
    case SET_DEPARTURE_DISPLAY:
      return {
        ...state,
        departureDisplay: payload,
      };
    case SET_DESTINATION_DISPLAY:
      return {
        ...state,
        destinationDisplay: payload,
      };
    case SET_DEPARTURE_FILTERS:
      return {
        ...state,
        departureFilters: payload,
      };
    case SET_DESTINATION_FILTERS:
      return {
        ...state,
        destinationFilters: payload,
      };
    default:
      return state;
  }
};
