import React, { useState, useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import Confetti from 'react-dom-confetti';
import * as firebase from 'firebase/app';
import 'firebase/functions';

import Translate from '../utilities/Translate';
import useTranslation from '../utilities/useTranslation';
import { Redirect } from 'react-router-dom';

const SignUpForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [customerCreated, setCustomerCreated] = useState(false);
  const [cardDeclined, setCardDeclined] = useState(false);
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const translation = useTranslation();
  const isLocal = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

  useEffect(() => {
    if (completed) {
      setTimeout(() => setRedirect(true), 2000);
    }
  }, [completed]);
  console.log(isLocal);

  const createSubscription = ({ userId, paymentMethodId, priceId }) => {
    return (
      firebase
        .functions()
        .httpsCallable('stripe-testCreateSubscription')({
          userId,
          paymentMethodId,
          priceId,
        })
        // If the card is declined, display an error to the user.
        .then(({ data }) => {
          console.log('throwing first');
          console.log(data);
          if (data.error) {
            // The card had an error when trying to attach it to a customer.
            throw data;
          }
          return data;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((data) => {
          console.log('data');
          console.log(data);

          return {
            paymentMethodId,
            priceId,
            subscription: data,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail, you
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          setCardDeclined(true);
          setLoading(false);
          console.log(error);
        })
    );
  };

  const handleRequiresPaymentMethod = ({ subscription, paymentMethodId, priceId }) => {
    console.log('handlePaymentThatRequiresCustomerAction');

    if (subscription.status === 'active') {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } else if (subscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
      // Using localStorage to manage the state of the retry here,
      // feel free to replace with what you prefer.
      // Store the latest invoice ID and status.
      localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
      localStorage.setItem('latestInvoicePaymentIntentStatus', subscription.latest_invoice.payment_intent.status);
      return { error: { message: 'Your card was declined.' } };
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  };

  // const retryInvoiceWithNewPaymentMethod = ({ userId, paymentMethodId, invoiceId, priceId }) => {
  //   return (
  //     fetch('/retry-invoice', {
  //       method: 'post',
  //       headers: {
  //         'Content-type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         userId,
  //         paymentMethodId,
  //         invoiceId,
  //       }),
  //     })
  //       .then((response) => {
  //         return response.json();
  //       })
  //       // If the card is declined, display an error to the user.
  //       .then((result) => {
  //         if (result.error) {
  //           // The card had an error when trying to attach it to a customer.
  //           throw result;
  //         }
  //         return result;
  //       })
  //       // Normalize the result to contain the object returned by Stripe.
  //       // Add the additional details we need.
  //       .then((result) => {
  //         return {
  //           // Use the Stripe 'object' property on the
  //           // returned result to understand what object is returned.
  //           invoice: result,
  //           paymentMethodId: paymentMethodId,
  //           priceId: priceId,
  //           isRetry: true,
  //         };
  //       })
  //       // Some payment methods require a customer to be on session
  //       // to complete the payment process. Check the status of the
  //       // payment intent to handle these actions.
  //       .then(handlePaymentThatRequiresCustomerAction)
  //       // No more actions required. Provision your service for the user.
  //       .then(onSubscriptionComplete)
  //       .catch((error) => {
  //         // An error has happened. Display the failure to the user here.
  //         // We utilize the HTML element we created.
  //         displayError(error);
  //       })
  //   );
  // };

  const onSubscriptionComplete = (result) => {
    console.log('onSubscriptionComplete');
    // Payment was successful.
    if (result.subscription.status === 'active') {
      // Change your UI to show a success message to your customer.
      // Call your backend to grant access to your service based on
      // `result.subscription.items.data[0].price.product` the customer subscribed to.
      setCompleted(true);
      setLoading(false);
    }
  };

  const handlePaymentThatRequiresCustomerAction = ({ subscription, invoice, priceId, paymentMethodId, isRetry }) => {
    console.log('handlePaymentThatRequiresCustomerAction');
    console.log(subscription);
    console.log(invoice);

    if (subscription && subscription.status === 'active') {
      // Subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    console.log('requires action');
    console.log('invoice');
    console.log(invoice);
    console.log('subscription');
    console.log(subscription);
    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // Start code flow to handle updating the payment details.
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc).
            throw result;
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              // Show a success message to your customer.
              // There's a risk of the customer closing the window before the callback.
              // We recommend setting up webhook endpoints later in this guide.
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
              };
            }
          }
        })
        .catch((error) => {
          displayError(error);
        });
    } else {
      // No customer action needed.
      return { subscription, priceId, paymentMethodId };
    }
  };

  const displayError = (error) => {
    console.log(error);
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const { data: userId } = await firebase.functions().httpsCallable('stripe-testCreateCustomerOnCall')({
      name,
      email,
      live: true,
    });

    setCustomerCreated(true);

    const priceId = 'price_1HWirl4PkqzVRwVIfYTialjm'; // live

    const cardNumberElement = elements.getElement(CardNumberElement);
    console.log(cardNumberElement);

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
    });
    const { error, paymentMethod } = result;

    console.log('Create payment method');
    console.log(result);

    if (error) {
      console.log('[error]', error);
      setCardDeclined(true);
      setLoading(false);
    } else {
      console.log('[PaymentMethod]', paymentMethod);

      const paymentMethodId = paymentMethod.id;

      console.log(userId);

      // if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
      // Update the payment method and retry invoice payment
      // const invoiceId = localStorage.getItem('latestInvoiceId');
      // retryInvoiceWithNewPaymentMethod({
      //   userId,
      //   paymentMethodId,
      //   invoiceId,
      //   priceId,
      // });
      // } else {
      // Create the subscription
      createSubscription({ userId, paymentMethodId, priceId });
      // }
    }
  };

  if (redirect) {
    return <Redirect to="/signupcomplete" />;
  }

  return (
    <div className="sign-up-form">
      <h3>
        <Translate>Sign up</Translate>
      </h3>
      <form onSubmit={onSubmit}>
        <div className="sign-up-form-name">
          {/* <label htmlFor="name">Name</label> */}
          <input
            type="text"
            id="name"
            placeholder={translation('Name')}
            disabled={loading}
            value={name}
            onChange={(input) => setName(input.target.value)}
          />
        </div>
        <div className="sign-up-form-email">
          {/* <label htmlFor="email">Email</label> */}
          <input
            type="email"
            id="email"
            placeholder={translation('Email')}
            disabled={loading}
            value={email}
            onChange={(input) => setEmail(input.target.value)}
          />
        </div>
        <br />
        <br />

        <div className="sign-up-form-card-number">
          <label htmlFor="card-number">
            <Translate>Payment details</Translate>
          </label>
          <CardNumberElement
            options={{
              disabled: loading,
              showIcon: true,
              style: {
                base: {
                  fontSize: '15px',
                  fontWeight: 'normal',
                  color: '#495057',
                },
              },
            }}
          />
        </div>

        <div className="sign-up-form-details">
          <div className="sign-up-form-card-cvc">
            <CardCvcElement
              options={{
                disabled: loading,
                style: {
                  base: {
                    fontSize: '15px',
                    fontWeight: 'normal',
                    color: '#495057',
                  },
                },
              }}
            />
          </div>

          <div className="sign-up-form-card-expires">
            <CardExpiryElement
              options={{
                disabled: loading,
                style: {
                  base: {
                    fontSize: '15px',
                    fontWeight: 'normal',
                    color: '#495057',
                  },
                },
              }}
            />
          </div>
        </div>
        <Confetti
          active={completed}
          config={{
            angle: 90,
            spread: 360,
            startVelocity: 40,
            elementCount: 120,
            dragFriction: 0.12,
            duration: 3000,
            stagger: 3,
            width: '10px',
            height: '10px',
            perspective: '500px',
            colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
          }}
        />

        {cardDeclined && (
          <div className="sign-up-creating-account">
            <h3>
              <Translate>Payment declined, please try another card.</Translate>
            </h3>
          </div>
        )}

        {loading ? (
          <div className="sign-up-creating-account">
            <h3>
              {customerCreated ? (
                <Translate>Verifying payment...</Translate>
              ) : (
                <Translate>Creating account...</Translate>
              )}
            </h3>
            <Loader active inline />
          </div>
        ) : (
          <button type="submit" disabled={!stripe || loading}>
            <Translate>Go premium</Translate>
          </button>
        )}
      </form>
      <img src="/powered_by_stripe.png" alt="" />
      <br />
      <Translate>Payment disclaimer</Translate>
    </div>
  );
};

export default SignUpForm;
