import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useWindowDimensions from '../utilities/useWindowDimensions';

import CountryRestrictionsList from '../utilities/countryRestrictionsList/CountryRestrictionsList';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { setHoverDestinationCountry, setDestinationCountry, fetchCountry } from '../../store/actions';

import getCountryColor from '../utilities/getCountryColor';
import MapFilters from '../utilities/mapFilters/MapFilters';
import MapInformation from '../utilities/mapInformation/MapInformation';
import MapLegend from '../utilities/mapLegend/MapLegend';

import countries from '../../countries.json';

import './travelFrom.scss';

const TravelFrom = () => {
  const dispatch = useDispatch();
  const {
    app: { departureCountry, departureFilters, departureDisplay },
    country: { outbound },
  } = useSelector((state) => state);
  useEffect(() => {
    if (departureCountry) {
      dispatch(fetchCountry(departureCountry));
    }
  }, [departureCountry]);
  const { width } = useWindowDimensions();
  const history = useHistory();

  return (
    <div className="travel-from">
      <MapFilters isDeparture showFilters={departureDisplay === 'map'} />
      <MapInformation />
      {departureDisplay === 'map' ? (
        <>
          <ComposableMap
            projection="geoMercator"
            className="home-content-map"
            style={{ width: '100%', height: width >= 1200 ? 780 : width >= 400 ? 780 - (1200 - width) / 1.2 : 230 }}
            projectionConfig={{
              scale: width >= 1200 ? 130 : width >= 400 ? 130 + (1200 - width) / 10 : 140,
              center: [8, 44],
            }}
          >
            <Geographies geography={countries}>
              {({ geographies }) =>
                geographies.map((geo) => {
                  return (
                    <Geography
                      onClick={() => {
                        const country = geo.properties.ISO_A2.toLowerCase();
                        dispatch(setDestinationCountry(country));
                        history.push(`/country/${country}`);
                      }}
                      key={geo.rsmKey}
                      geography={geo}
                      onMouseLeave={() => dispatch(setHoverDestinationCountry(''))}
                      onMouseEnter={() => dispatch(setHoverDestinationCountry(geo.properties.ISO_A2.toLowerCase()))}
                      style={{
                        default: {
                          fill: getCountryColor(
                            departureCountry,
                            geo.properties.ISO_A2.toLowerCase(),
                            departureFilters,
                            outbound
                          ),
                          stroke: '#eee',
                          strokeWidth: 0.5,
                          outline: 'none',
                          transition: 'all 250ms',
                        },
                        hover: {
                          fill: '#333',
                          stroke: '#222',
                          strokeWidth: 0.5,
                          outline: 'none',
                          transition: 'all 250ms',
                          cursor: 'pointer',
                        },
                        pressed: {
                          fill: '#222',
                          stroke: '#222',
                          strokeWidth: 0.75,
                          outline: 'none',
                          transition: 'all 150ms',
                          cursor: 'pointer',
                        },
                      }}
                    />
                  );
                })
              }
            </Geographies>
          </ComposableMap>
          <div className="travel-from-bottom">
            <MapLegend />
          </div>
        </>
      ) : (
        <CountryRestrictionsList restrictions={outbound} />
      )}
    </div>
  );
};

export default TravelFrom;
