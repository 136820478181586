import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Menu, Button, Icon, Flag } from 'semantic-ui-react';
import { fetchScraperUpdates, fetchAdminArticles } from '../../../store/actions';

const AdminArticleList = () => {
  const { articles } = useSelector((state) => state.admin);
  const { article: selectedArticle } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchScraperUpdates());
    dispatch(fetchAdminArticles());
  }, [dispatch]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Button fluid color="green" as={Link} to="/admin/articles">
        <Icon name="add" />
        New article
      </Button>
      <Menu pointing secondary vertical>
        {articles.map((article) => (
          <Menu.Item
            key={`article-${article.id}`}
            as={Link}
            active={selectedArticle === article.id}
            to={`/admin/articles/${article.id}`}
            name="inbox"
          >
            {article.language.map &&
              article.language.map((language) => (
                <Flag
                  key={`article-${article.id}-flag-${language}`}
                  name={language === 'en' ? 'us' : language === 'sv' ? 'se' : language === 'vi' ? 'vn' : ''}
                />
              ))}
            {article.header.en
              ? article.header.en
              : article.header.sv
              ? article.header.sv
              : article.header.vi
              ? article.header.vi
              : article.header}{' '}
            {!article.isPublished && <span style={{ color: '#aaa' }}>(draft)</span>}
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );
};

export default AdminArticleList;
