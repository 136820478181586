import React from 'react';
import Translate from '../Translate';

import './mapLegend.scss';

const MapLegend = () => {
  return (
    <div className="map-legend">
      <div className="map-legend-colors">
        <div className="map-legend-info">
          <div className="map-legend-info-open" />
          <span>
            <Translate>Open</Translate>
          </span>
        </div>
        <div className="map-legend-info">
          <div className="map-legend-info-restricted" />
          <span>
            <Translate>Restricted</Translate>
          </span>
        </div>
        <div className="map-legend-info">
          <div className="map-legend-info-closed" />
          <span>
            <Translate>Closed</Translate>
          </span>
        </div>
        <div className="map-legend-info">
          <div className="map-legend-info-unknown" />
          <span>
            <Translate>Unknown</Translate>
          </span>
        </div>
      </div>
      <div className="map-legend-disclaimer">
        <Translate>Map disclaimer</Translate>
      </div>
    </div>
  );
};

export default MapLegend;
