import { FETCH_COUNTRIES_SUCCESS, FETCH_COUNTRY_UPDATES_SUCCESS } from '../types';

import moment from 'moment';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

export const fetchCountries = () => {
  return async (dispatch, getState) => {
    const countriesDoc = await firebase.firestore().collection('directory').doc('countries').get();

    dispatch({
      type: FETCH_COUNTRIES_SUCCESS,
      payload: countriesDoc.data(),
    });
  };
};

export const fetchCountryUpdates = () => {
  return async (dispatch, getState) => {
    const countriesDoc = await firebase.firestore().collection('directory').doc('updates').get();
    const { countryUpdates } = countriesDoc.data();
    const payload = Object.entries(countryUpdates)
      .map(([_, { name, country, updated, url }]) => ({ name, country, updated, url }))
      .sort(({ updated: { seconds: a } }, { updated: { seconds: b } }) =>
        moment(a * 1000).isBefore(moment(b * 1000)) ? 1 : -1
      );

    dispatch({
      type: FETCH_COUNTRY_UPDATES_SUCCESS,
      payload,
    });
  };
};
