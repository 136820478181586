import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import Translate from '../utilities/Translate';

import { hidePremiumBanner } from '../../store/actions';

import './premiumBanner.scss';

const PremiumBanner = () => {
  const dispatch = useDispatch();

  return (
    <div className="premium-banner-container">
      <Link to="/signup">
        <img src="/notification_bell_white.png" alt="" />
        <Translate>Premium banner</Translate>
      </Link>
      <button className="close" onClick={() => dispatch(hidePremiumBanner())} />
    </div>
  );
};

export default PremiumBanner;
