import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import Translate from '../utilities/Translate';
import useTranslation from '../utilities/useTranslation';

import { setDestinationCountry, fetchCountry } from '../../store/actions';

import './selectDestinationCountry.scss';

const SelectDestinationCountry = () => {
  const dispatch = useDispatch();
  const {
    countries: { list },
    app: { hoverDestinationCountry },
  } = useSelector((state) => state);
  const translation = useTranslation();
  const history = useHistory();

  return (
    <div className="select-destination-country-container">
      <div className="select-destination-country">
        <Select
          onChange={({ value }) => {
            dispatch(setDestinationCountry(value));
            dispatch(fetchCountry(value));
            history.push(`/country/${value}`);
          }}
          noOptionsMessage={() => <Translate>No matches...</Translate>}
          placeholder={
            <div className="select-destination-country-placeholder">
              {false && hoverDestinationCountry && list[hoverDestinationCountry] ? (
                <Translate>{list[hoverDestinationCountry].name}</Translate>
              ) : (
                <Translate>Choose your destination</Translate>
              )}
            </div>
          }
          styles={{
            control: (provided, state) => ({
              ...provided,
              borderTopRightRadius: 31,
              borderBottomRightRadius: 31,
            }),
            indicatorSeparator: (provided, state) => ({
              display: 'none',
            }),
            dropdownIndicator: (provided, state) => ({
              ...provided,
              color: '#000',
              marginRight: -8,
              transform: 'scale(1.6)',
            }),
          }}
          theme={{
            borderRadius: 0,
            colors: {
              neutral0: '#ffffff',
              primary25: '#fff0d7',
              primary50: '#e0b163',
            },
            spacing: { baseUnit: 4, controlHeight: 62, menuGutter: 0 },
          }}
          filterOption={({ data: { name } }, search) => {
            return name.includes(search.toLowerCase());
          }}
          options={Object.entries(list)
            .map(([key, { name }]) => ({ key, country: name }))
            .sort(({ country: a }, { country: b }) => (translation(a) < translation(b) ? -1 : 1))
            .map(({ key, country }) => ({
              value: key,
              name: translation(country).toLowerCase(),
              label: (
                <div className="select-destination-country-option">
                  <img src={`/flags/${key.toUpperCase()}.png`} alt="" />
                  {translation(country)}
                </div>
              ),
            }))}
        />
      </div>
    </div>
  );
};

export default SelectDestinationCountry;
