import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import AdminSignIn from './AdminSignIn';
import AdminTopMenu from './AdminTopMenu';
import AdminFooter from './AdminFooter';
import CountryList from './countries/CountryList';
import AdminArticles from './articles/AdminArticles';
import Dashboard from './dashboard/Dashboard';
import Users from './users/Users';
import Finance from './finance/Finance';

import 'semantic-ui-css/semantic.min.css';

const Admin = (props) => {
  const { signingIn, signedIn } = useSelector((state) => state.user);

  if (signingIn) {
    return <Loader size="massive" active />;
  }

  if (signedIn) {
    return (
      <div>
        <AdminTopMenu />
        <div style={{ marginLeft: 24, marginRight: 24, marginTop: 76, minHeight: '75vh' }}>
          <Switch>
            <Route exact path="/admin" component={Dashboard} />
            <Route path="/admin/countries/:country" component={CountryList} />
            <Route path="/admin/countries" component={CountryList} />
            <Route path="/admin/articles/:article" component={AdminArticles} />
            <Route path="/admin/articles" component={AdminArticles} />
            <Route path="/admin/users" component={Users} />
            <Route path="/admin/finance" component={Finance} />
          </Switch>
        </div>

        <AdminFooter />
      </div>
    );
  }

  return <AdminSignIn />;
};

export default Admin;
