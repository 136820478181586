import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Segment, Input, Grid, Card, Button, Icon, Flag, Checkbox, Label, Header, Dropdown } from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import * as firebase from 'firebase/app';
import 'firebase/functions';

import { saveArticle, deleteArticle } from '../../../store/actions';

import AdminArticleImage from './AdminArticleImage';

const AdminArticleEditor = () => {
  const { article: selectedArticle } = useParams();
  const dispatch = useDispatch();
  const { articles, newArticleId } = useSelector((state) => state.admin);
  const article = articles.filter(({ id }) => id === selectedArticle)[0];
  const savingArticle = useSelector((state) => state.admin.savingArticle);

  const [language, setLanguage] = useState(['sv']);
  const [isPublished, setIsPublished] = useState(true);
  const [published, setPublished] = useState(moment().format('YYYY-MM-DD HH:00'));
  const [image, setImage] = useState(false);
  const [header, setHeader] = useState({ en: '', sv: '', vi: '' });
  const [ingress, setIngress] = useState({ en: '', sv: '', vi: '' });
  const [blocks, setBlocks] = useState([]);
  const [deleteWarning, setDeleteWarning] = useState(-1);
  const [deleteArticleWarning, setDeleteArticleWarning] = useState(false);
  const [translationInProgress, setTranslationInProgress] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (article) {
      setImage(article.image);
      setHeader(article.header);
      setIngress(article.ingress);
      setBlocks(article.blocks);
      setDeleteWarning(-1);
      setLanguage(article.language || ['en', 'sv', 'vi']);
      setIsPublished(article.isPublished || false);
      setPublished(moment(article.published.seconds * 1000).format('YYYY-MM-DD HH:mm'));
      setDeleteArticleWarning(false);
    } else {
      setImage(false);
      setHeader({ sv: '', en: '', vi: '' });
      setIngress({ sv: '', en: '', vi: '' });
      setBlocks([{ type: 'text', text: { en: '', sv: '', vi: '' } }]);
      setDeleteWarning(-1);
      setLanguage(['sv']);
      setIsPublished(true);
      setPublished(moment().format('YYYY-MM-DD HH:00'));
      setDeleteArticleWarning(false);
    }
  }, [article]);

  useEffect(() => {
    if (!selectedArticle && newArticleId !== selectedArticle) {
      history.push(`/admin/articles/${newArticleId}`);
    }
  }, [newArticleId]);

  const translateArticle = async (source) => {
    setTranslationInProgress(true);

    const targets = language.filter((l) => l !== source);

    const newHeader = { ...header };
    for (const target of targets) {
      if (newHeader[target] === '') {
        const {
          data: { translation },
        } = await firebase.functions().httpsCallable('admin-translate')({
          source,
          target,
          text: header[source],
        });
        newHeader[target] = translation.replace(/&#39;/g, "'");
      }
    }
    setHeader(newHeader);

    const newIngress = { ...ingress };
    for (const target of targets) {
      if (newIngress[target] === '') {
        const {
          data: { translation },
        } = await firebase.functions().httpsCallable('admin-translate')({
          source,
          target,
          text: ingress[source].trim(),
        });
        newIngress[target] = translation.replace(/&#39;/g, "'");
      }
    }
    setIngress(newIngress);

    const newBlocks = [...blocks];
    let index = 0;
    for (const block of blocks) {
      for (const target of targets) {
        if (block.type === 'image') {
          if (!block.caption[target] || block.caption[target] === '') {
            const {
              data: { translation },
            } = await firebase.functions().httpsCallable('admin-translate')({
              source,
              target,
              text: block.caption[source].trim(),
            });
            newBlocks[index].caption[target] = translation;
          }
        } else {
          if (!block.text[target] || block.text[target] === '') {
            const {
              data: { translation },
            } = await firebase.functions().httpsCallable('admin-translate')({
              source,
              target,
              text: block.text[source].trim().replace(/\n/g, '[]'),
            });
            newBlocks[index].text[target] = translation
              .replace(/ \[\] \[\] /g, '\n\n')
              .replace(/ \[\] /g, '\n')
              .replace(/ \[ \]/g, '\n')
              .replace(/&#39;/g, "'");
          }
        }
      }

      index += 1;
    }
    setBlocks(newBlocks);

    setTranslationInProgress(false);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const {
      source: { index: source },
      destination: { index: destination },
    } = result;

    const sourceBlock = blocks[source];
    const previousBlocks = blocks.filter((_, index) => index !== source);

    setBlocks(
      destination === blocks.length - 1
        ? [...previousBlocks, sourceBlock]
        : previousBlocks.reduce(
            (acc, block, index) => (index === destination ? [...acc, sourceBlock, block] : [...acc, block]),
            []
          )
    );
  };

  const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver ? 'lightblue' : 'lightgrey',
    // width: 250,
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    ...draggableStyle,
  });

  const getLanguageHeader = (language) => {
    if (language === 'en') {
      return (
        <>
          <Header>
            <Flag name="us" /> English
          </Header>
        </>
      );
    } else if (language === 'sv') {
      return (
        <>
          <Header>
            <Flag name="se" /> Swedish
          </Header>
        </>
      );
    } else if (language === 'vi') {
      return (
        <>
          <Header>
            <Flag name="vn" /> Vietnamese
          </Header>
        </>
      );
    }
  };

  return (
    <Segment style={{ width: '100%', marginLeft: 24 }}>
      <div>
        <Grid style={{ marginBottom: 0 }}>
          <Grid.Row>
            <Grid.Column width={2}>
              <Checkbox
                style={{ marginTop: 10 }}
                toggle
                checked={isPublished}
                onChange={(event, { checked: isPublished }) => setIsPublished(isPublished)}
              />
            </Grid.Column>
            <Grid.Column width={5}>
              <Input fluid value={published} onChange={(input) => setPublished(input.target.value)} />
            </Grid.Column>
            <Grid.Column width={4}>
              <Dropdown
                text="Translate"
                icon="globe"
                floating
                labeled
                button
                className="icon"
                loading={translationInProgress}
              >
                <Dropdown.Menu>
                  <Dropdown.Header content="Translate from" />
                  {[
                    {
                      key: 'en',
                      text: 'English',
                      flag: 'us',
                    },
                    {
                      key: 'sv',
                      text: 'Swedish',
                      flag: 'se',
                    },
                    {
                      key: 'vi',
                      text: 'Vietnamese',
                      flag: 'vn',
                    },
                  ].map((option) => (
                    <Dropdown.Item
                      disabled={!language.includes(option.key)}
                      key={option.key}
                      onClick={() => translateArticle(option.key)}
                    >
                      <Flag name={option.flag} /> {option.text}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Grid.Column>
            <Grid.Column width={5}>
              <Grid columns="equal">
                <Grid.Row>
                  <Grid.Column>
                    {[
                      ['vi', 'vn'],
                      ['sv', 'se'],
                      ['en', 'us'],
                    ].map(([l, flag]) => (
                      <Button
                        key={`language-${l}`}
                        onClick={() => {
                          if (language.length > 1 || language[0] !== l)
                            language.includes(l)
                              ? setLanguage(language.filter((language) => language !== l))
                              : setLanguage([...language, l]);
                        }}
                        basic={!language.includes(l)}
                        color="black"
                        circular
                        style={{ float: 'right', padding: 8 }}
                      >
                        <Flag name={flag} style={{ marginLeft: 6 }} />
                      </Button>
                    ))}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <AdminArticleImage image={image} setImage={(image) => setImage(image)} />
        <Grid columns="equal" style={{ marginLeft: 16, marginRight: 44 }}>
          <Grid.Row>
            {language
              .sort((a, b) => (a < b ? -1 : 1))
              .map((language) => (
                <Grid.Column key={`header-${language}`}>
                  <Label style={{ marginTop: 16 }}>{getLanguageHeader(language)}</Label>
                  <Input
                    size="huge"
                    placeholder="Header ..."
                    fluid
                    value={header[language]}
                    onChange={(input) => setHeader({ ...header, [language]: input.target.value })}
                  />
                </Grid.Column>
              ))}
          </Grid.Row>
        </Grid>

        <Grid columns="equal" style={{ marginLeft: 17, marginRight: 44 }}>
          <Grid.Row>
            {language
              .sort((a, b) => (a < b ? -1 : 1))
              .map((language) => (
                <Grid.Column key={`ingress-${language}`}>
                  <Card fluid>
                    <Card.Content>
                      <TextareaAutosize
                        style={{
                          width: '100%',
                          border: 0,
                          fontSize: 18,
                          fontStyle: 'italic',
                          '&:focus': 'outline: none',
                        }}
                        minRows={3}
                        value={ingress[language]}
                        placeholder="Ingress ..."
                        onChange={(input) => setIngress({ ...ingress, [language]: input.target.value })}
                      />
                    </Card.Content>
                  </Card>
                </Grid.Column>
              ))}
          </Grid.Row>
        </Grid>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                {blocks.map((block, index) => (
                  <Draggable key={`block-${index}`} draggableId={`block-${index}`} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <div style={{ display: 'flex' }}>
                          <Icon name="move" style={{ marginTop: 16, marginRight: 8 }} />
                          {block.type === 'text' ? (
                            language
                              .sort((a, b) => (a < b ? -1 : 1))
                              .map((language) => (
                                <Card
                                  fluid
                                  key={`block-${index}-${language}`}
                                  style={{ marginLeft: 8, marginRight: 8, marginBottom: 16 }}
                                >
                                  <Label>{getLanguageHeader(language)}</Label>
                                  <Card.Content>
                                    <TextareaAutosize
                                      style={{ width: '100%', border: 0 }}
                                      minRows={5}
                                      value={block.text[language]}
                                      onChange={(input) =>
                                        setBlocks(
                                          blocks.map((block, i) =>
                                            index === i
                                              ? { ...block, text: { ...block.text, [language]: input.target.value } }
                                              : block
                                          )
                                        )
                                      }
                                    />
                                  </Card.Content>
                                </Card>
                              ))
                          ) : block.type === 'subheader' ? (
                            language
                              .sort((a, b) => (a < b ? -1 : 1))
                              .map((language) => (
                                <Card fluid key={`block-${index}-${language}`} style={{ margin: 8 }}>
                                  <Label>{getLanguageHeader(language)}</Label>
                                  <Card.Content>
                                    <Input
                                      size="big"
                                      fluid
                                      value={block.text[language]}
                                      onChange={(input) =>
                                        setBlocks(
                                          blocks.map((block, i) =>
                                            index === i
                                              ? { ...block, text: { ...block.text, [language]: input.target.value } }
                                              : block
                                          )
                                        )
                                      }
                                    />
                                  </Card.Content>
                                </Card>
                              ))
                          ) : block.type === 'image' ? (
                            <Card fluid>
                              <Card.Content>
                                <AdminArticleImage
                                  image={block.image}
                                  setImage={(image) =>
                                    setBlocks(blocks.map((block, i) => (index === i ? { ...block, image } : block)))
                                  }
                                />
                                {block.image && (
                                  <Grid columns="equal">
                                    <Grid.Row>
                                      {language
                                        .sort((a, b) => (a < b ? -1 : 1))
                                        .map((language) => (
                                          <Grid.Column key={`block-${index}-${language}`}>
                                            <Card fluid style={{ margin: 8 }}>
                                              <Label>{getLanguageHeader(language)}</Label>
                                              <Card.Content>
                                                <TextareaAutosize
                                                  style={{
                                                    width: '100%',
                                                    border: 0,
                                                    fontSize: 16,
                                                    padding: 8,
                                                    marginTop: 4,
                                                  }}
                                                  placeholder="Caption ..."
                                                  value={block.caption[language]}
                                                  onChange={(input) =>
                                                    setBlocks(
                                                      blocks.map((block, i) =>
                                                        index === i
                                                          ? {
                                                              ...block,
                                                              caption: {
                                                                ...block.caption,
                                                                [language]: input.target.value,
                                                              },
                                                            }
                                                          : block
                                                      )
                                                    )
                                                  }
                                                />
                                              </Card.Content>
                                            </Card>
                                          </Grid.Column>
                                        ))}
                                    </Grid.Row>
                                  </Grid>
                                )}
                              </Card.Content>
                            </Card>
                          ) : (
                            <div />
                          )}
                          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8, marginTop: 16 }}>
                            {deleteWarning === index ? (
                              <>
                                <Button circular icon="cancel" onClick={() => setDeleteWarning(-1)} />
                                <Button
                                  color="red"
                                  circular
                                  icon="trash"
                                  style={{ marginTop: 8 }}
                                  onClick={() => {
                                    setBlocks(blocks.filter((_, i) => i !== index));
                                    setDeleteWarning(-1);
                                  }}
                                />
                              </>
                            ) : (
                              <Button
                                color="red"
                                circular
                                icon="trash"
                                style={{}}
                                onClick={() => setDeleteWarning(index)}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Button
              color="black"
              onClick={() =>
                setBlocks([
                  ...blocks,
                  { type: 'text', text: language.reduce((acc, next) => ({ ...acc, [next]: '' }), {}) },
                ])
              }
            >
              <Icon name="plus" />
              Add Text
            </Button>
            <Button
              color="black"
              onClick={() =>
                setBlocks([
                  ...blocks,
                  { type: 'subheader', text: language.reduce((acc, next) => ({ ...acc, [next]: '' }), {}) },
                ])
              }
            >
              <Icon name="plus" />
              Add Subheader
            </Button>
            <Button color="black" onClick={() => setBlocks([...blocks, { type: 'image', image: false, caption: '' }])}>
              <Icon name="image" />
              Add Image
            </Button>
          </div>
          <div>
            <Button
              loading={savingArticle}
              disabled={savingArticle}
              color="green"
              onClick={() =>
                dispatch(
                  saveArticle(
                    {
                      image,
                      header,
                      ingress,
                      blocks,
                      published,
                      isPublished,
                      language,
                    },
                    selectedArticle
                  )
                )
              }
            >
              <Icon name="save" />
              Save
            </Button>
          </div>
        </div>
        {article &&
          (deleteArticleWarning ? (
            <Grid columns={2} style={{ marginTop: 48 }}>
              <Grid.Row>
                <Grid.Column>
                  <Button fluid onClick={() => setDeleteArticleWarning(false)}>
                    <Icon name="cancel" /> Cancel
                  </Button>
                </Grid.Column>
                <Grid.Column>
                  <Button
                    fluid
                    color="red"
                    onClick={() => {
                      dispatch(deleteArticle(article.id));
                      setDeleteArticleWarning(false);
                      history.push('/admin/articles');
                    }}
                  >
                    <Icon name="trash" /> Confirm Deletion
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          ) : (
            <Grid columns={1} style={{ marginTop: 48 }}>
              <Grid.Row>
                <Grid.Column>
                  <Button fluid color="red" onClick={() => setDeleteArticleWarning(true)}>
                    <Icon name="trash" /> Delete Article
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          ))}
      </div>
    </Segment>
  );
};

export default AdminArticleEditor;
