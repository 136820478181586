import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Loader } from 'semantic-ui-react';

import Translate from '../utilities/Translate';
import useTranslation from '../utilities/useTranslation';
import useValidateEmail from '../utilities/useValidateEmail';

import { setLanguage, sendSignInEmail, toggleMenu } from '../../store/actions';

import './header.scss';

const Header = () => {
  const [signIn, setSignIn] = useState(false);
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const dispatch = useDispatch();
  const {
    locale: { active },
    user: {
      signedIn,
      signingIn,
      signInEmailSent,
      user: { name, isAdmin },
    },
  } = useSelector((state) => state);
  const { pathname } = useLocation();
  const translation = useTranslation();
  const validateEmail = useValidateEmail();

  return (
    <>
      {isAdmin && (
        <div className="main-header-admin">
          <Link to="/admin">Administrator</Link>
        </div>
      )}
      <header className="main-header">
        <div className="main-header-container">
          <Link to="/">
            <img className="main-header-logo" src="/logo.png" alt="update.travel" />
          </Link>
          {signingIn ? (
            <Loader active inline />
          ) : (
            <div className="main-header-right-desktop">
              <div className="main-header-language">
                <span
                  onClick={() => {
                    dispatch(setLanguage('en'));
                    localStorage.setItem('language', 'en');
                  }}
                  style={{ color: active === 'en' ? '#f7a80d' : '#fff' }}
                >
                  EN
                </span>{' '}
                |{' '}
                <span
                  onClick={() => {
                    dispatch(setLanguage('sv'));
                    localStorage.setItem('language', 'sv');
                  }}
                  style={{ color: active === 'sv' ? '#f7a80d' : '#fff' }}
                >
                  SV
                </span>{' '}
                |{' '}
                <span
                  onClick={() => {
                    dispatch(setLanguage('vi'));
                    localStorage.setItem('language', 'vi');
                  }}
                  style={{ color: active === 'vi' ? '#f7a80d' : '#fff' }}
                >
                  VI
                </span>
              </div>
              {signedIn ? (
                <div className="main-header-user-container">
                  <Link to="/profile">
                    <Icon name="user outline" /> {name}
                  </Link>
                </div>
              ) : (
                <>
                  <div className="main-header-sign-in-container">
                    <button
                      onClick={() => {
                        if (!signInEmailSent) {
                          setSignIn(!signIn);
                        }
                      }}
                    >
                      <img src="/user.png" alt="" />
                      {!signIn && <Translate>Sign In</Translate>}
                    </button>
                  </div>
                  {signInEmailSent && (
                    <div className="main-header-signing-in">
                      <Translate>Check your email!</Translate>
                    </div>
                  )}
                  {signIn && !signInEmailSent && (
                    <form
                      onSubmit={(event) => {
                        event.preventDefault();
                        dispatch(sendSignInEmail(email, pathname.slice(1)));
                      }}
                      className="main-header-sign-in"
                    >
                      <input
                        value={email}
                        onChange={(input) => {
                          setEmail(input.target.value.toLowerCase());
                          setValidEmail(validateEmail(input.target.value.toLowerCase()));
                        }}
                        autoFocus
                        placeholder={translation('Email')}
                        className="main-header-sign-in"
                      />
                      <button type="submit" disabled={!validEmail}>
                        <img src="/sign_in_plane.png" alt="" />
                      </button>
                    </form>
                  )}
                </>
              )}
            </div>
          )}
          <div className="main-header-right-mobile">
            <div className="main-header-burger" onClick={() => dispatch(toggleMenu())}>
              <span />
              <span />
              <span />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
