import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header, Grid, Feed, Icon, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import moment from 'moment';

const DashboardFeed = (props) => {
  const {
    admin: { scraperUpdates },
    countries: { list },
  } = useSelector((state) => state);

  return (
    <>
      <Header>Latest updates</Header>
      <Feed>
        {scraperUpdates.map((scraper, index) => (
          <Feed.Event key={`event-${index}`}>
            <Feed.Label image={`/flags/${scraper.country.toUpperCase()}.png`} />
            <Feed.Content>
              <Feed.Summary>
                <Feed.User as={Link} to={`/admin/countries/${scraper.country}`}>
                  {list[scraper.country].name}
                </Feed.User>
                <Feed.Date>{moment(scraper.updated.seconds * 1000).fromNow()}</Feed.Date>
              </Feed.Summary>
              <Feed.Extra style={{ display: 'grid', gridTemplateColumns: '1fr 64px' }}>
                <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                  <a href={scraper.url} target="_blank" rel="noopener noreferer">
                    {scraper.url}
                  </a>
                </div>
              </Feed.Extra>
              <Feed.Meta>
                <Feed.Like>
                  <Icon name="mail" />0 emails sent
                </Feed.Like>
              </Feed.Meta>
            </Feed.Content>
          </Feed.Event>
        ))}
      </Feed>
    </>
  );
};

export default DashboardFeed;
