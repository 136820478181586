export default (current, country, filters, restrictions) => {
  if (country === current) {
    return '#333';
  } else if (filters.includes('closed') && restrictions.closed.includes(country)) {
    return '#e13535';
  } else if (filters.includes('restricted') && restrictions.restricted.includes(country)) {
    return '#ebc644';
  } else if (filters.includes('open') && restrictions.open.includes(country)) {
    return '#5faa6d';
  } else {
    return '#dddddd';
  }
};
