import React from 'react';
import Primary from '../templates/Primary';
import useTranslation from '../utilities/useTranslation';
import moment from 'moment';

import './about.scss';

const About = () => {
  const translation = useTranslation();

  return (
    <Primary title={translation('About update.travel')} hero="/about_us_hero_background.jpg">
      <div className="about-container">
        <p>
          Update.travel was built in the context of Covid-19 epidemia that is raging around the world. Our group of four
          members, actually two couples, wants to create something that will help everyone in this difficult time, and
          also to help ourselves when we have to live apart during this time. We know that travelling is a huge part of
          of many peoples' lives and when the world went into a lockdown, the travel industry immediately went into
          coma. Our hope is that this site will help people to be able to travel sooner.
        </p>
        <img className="about-us-right" src="about_us_andreas_milly.jpg" alt="" />
        <h3>Andreas &amp; Milly</h3>
        <p>
          Andreas and Milly both huge passion for travelling. They actually met when Andreas was travelling, and made
          their first trip together just a week later. Due to the outbreak Covid-19 they have not been able to meet for{' '}
          {moment().diff(moment('2020-01-28'), 'days')} days and are constantly looking for a way to be together. This
          was one of the main motivations behind building this service as we believe that many couples out there are
          expirencing similar obstacles.
        </p>
        <p>
          Milly has worked for a multinational corporation in the human resources field for 7 years as a team leader and
          has experience in business development. When she travels she really love to experience the local food culture.
          Her dream country to visit is Bhutan, the happiest country in the world.
        </p>
        <p>
          Andreas has just finished his studies in software engineering and is currently working as an individual
          contractor, as well as running his own projects. He has been travelling to 41 countries so far and his dream
          is to visit every country in the world. What he enjoys most about travelling is to expierience the local
          culture. His worst travel experience is getting sick when eating raw chicken in Thailand, and his best memory
          is from meeting his fiancée Milly in Vietnam.
        </p>
        <h3>Mathias &amp; Sophie</h3>
        <p>
          I have always been fascinated by flying. There are few things that can so abruptly change everyday life as a
          travel. From one day to the next you are in a whole new reality. Maybe you leave work, stress and musts and
          fly from a cloudy country to a green and sunny heaven where you only need to wear a pair of shorts. Or you
          land in the middle of a snow-covered landscape with miles-wide views somewhere on the other side of the earth.
          It does something to the soul and it's just as fascinating every time I think!
        </p>
        <img className="about-us-left" src="about_us_mathias_sophie.jpg" alt="" />
        <p>
          It is the aircraft that makes this possible! That's why my interest in aircraft has grown so much and reached
          almost nerdy proportions. In my search for experience, I have also gained a taste of the excellent service you
          can find on board in the form of various travel classes which of course involve the food and drink you are
          served.
        </p>
        <p>
          In a world where everything rolls on so incredibly fast all the time and reachability everywhere and at any
          time, something happens to me when I step on the plane and turn off my mobile phone. Tranquility ensues, no
          one can reach me and no one demands anything. The plane goes where it should without my intervention, just sit
          back and enjoy the experience.
        </p>
        <p>
          Having had the opportunity to take business class and first class trips has been a privilege. Now when the
          world has closed down, even these highly valued experiences have disappeared for a while. I long so
          unbelievably for the world to become "normal" again. It was from this that the idea of ​​updatde.travel
          started, a longing to know when everything will be normal again or at least when it is possible to travel to
          our favorite destinations.
        </p>
        <p>
          Now in these times, people have probably never longed so much to travel away for a while as now when there are
          many current restrictions that can be difficult to break. But hopefully we can travel to the destinations of
          our dreams and once again be able to experience the feeling that a wonderful journey starts already at the
          airport in a lovely lounge.
        </p>
        <p>Welcome to update.travel, hope you will benefit from our features and our articles on the site.</p>
      </div>
    </Primary>
  );
};

export default About;
