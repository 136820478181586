import { FETCH_COUNTRIES_SUCCESS, FETCH_COUNTRY_UPDATES_SUCCESS } from '../types';

const INITIAL_STATE = {
  list: {},
  updates: [],
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        list: payload,
      };
    case FETCH_COUNTRY_UPDATES_SUCCESS:
      return {
        ...state,
        updates: payload,
      };
    default:
      return state;
  }
};
