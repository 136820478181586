import React from 'react';
import { Loader } from 'semantic-ui-react';

import './primaryButton.scss';

const PrimaryButton = ({ onClick, background, center, active, disabled, loading, image, children }) => {
  return (
    <button
      style={background ? { background } : {}}
      className={`primary-button${active ? ' primary-button-active' : ''}${center ? ' primary-button-center' : ''}`}
      disabled={disabled}
      onClick={onClick}
    >
      {loading ? (
        <div>
          <Loader active inline inverted size="small" />
        </div>
      ) : image ? (
        <div>
          <img src={image} alt="" />
        </div>
      ) : (
        ''
      )}
      {children}
    </button>
  );
};

export default PrimaryButton;
