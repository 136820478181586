import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Icon, Button, Checkbox } from 'semantic-ui-react';

import { setCookieConsent } from '../../store/actions';

const CookieConsentModal = () => {
  const dispatch = useDispatch();

  const { hasSeenCookieConsentModal } = useSelector((state) => state.app);
  const [acceptedCookies, setAcceptedCookies] = useState({
    necessary: true,
    analytics: true,
    language: true,
  });

  return (
    <Modal open={!hasSeenCookieConsentModal}>
      <Modal.Header icon="browser" content="Cookies policy" />
      <Modal.Content>
        <h3>This website uses cookies to ensure the best user experience.</h3>
        <Checkbox disabled checked label="Necessary" />
        <br />
        <Checkbox
          checked={acceptedCookies.analytics}
          onChange={(_, { checked }) => setAcceptedCookies({ ...acceptedCookies, analytics: checked })}
          label="Analytics"
        />
      </Modal.Content>
      <Modal.Actions>
        <Button color="green" onClick={() => dispatch(setCookieConsent(acceptedCookies))}>
          <Icon name="checkmark" /> Set cookies preferences
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CookieConsentModal;
