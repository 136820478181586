import {
  FETCH_SCRAPER_UPDATES,
  FETCH_USERS,
  FETCH_ADMIN_ARTICLES,
  SAVE_ARTICLE_BEGIN,
  SAVE_ARTICLE_SUCCESS,
  SAVE_ARTICLE_FAILURE,
} from '../types';

import moment from 'moment';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

export const fetchScraperUpdates = () => {
  return async (dispatch, getState) => {
    const scraperSnapshot = await firebase
      .firestore()
      .collectionGroup('scrapers')
      .where('acknowledged', '==', false)
      .get();

    dispatch({
      type: FETCH_SCRAPER_UPDATES,
      payload: scraperSnapshot.docs.map((doc) => doc.data()),
    });
  };
};
export const fetchUsers = () => {
  return async (dispatch, getState) => {
    const { docs } = await firebase.firestore().collection('users').orderBy('created', 'desc').get();

    dispatch({
      type: FETCH_USERS,
      payload: docs.map((doc) => ({ id: doc.id, ...doc.data() })),
    });
  };
};

export const fetchAdminArticles = () => {
  return async (dispatch, getState) => {
    const { docs } = await firebase.firestore().collection('articles').orderBy('published', 'desc').get();

    dispatch({
      type: FETCH_ADMIN_ARTICLES,
      payload: docs.map((doc) => ({ id: doc.id, ...doc.data() })),
    });
  };
};

export const saveArticle = ({ image, header, ingress, blocks, published, isPublished, language }, id) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SAVE_ARTICLE_BEGIN,
    });

    if (id) {
      await firebase
        .firestore()
        .collection('articles')
        .doc(id)
        .update({
          image,
          header,
          ingress,
          blocks,
          published: moment(published).toDate(),
          isPublished,
          language,
        });
      dispatch({
        type: SAVE_ARTICLE_SUCCESS,
        payload: id,
      });
    } else {
      const { id: newId } = await firebase
        .firestore()
        .collection('articles')
        .add({
          image,
          header,
          ingress,
          blocks,
          published: moment(published).toDate(),
          isPublished,
          language,
        });
      dispatch({
        type: SAVE_ARTICLE_SUCCESS,
        payload: newId,
      });
    }

    dispatch(fetchAdminArticles());
  };
};

export const deleteArticle = (id) => {
  return async (dispatch, getState) => {
    await firebase.firestore().collection('articles').doc(id).delete();
    dispatch(fetchAdminArticles());
  };
};
