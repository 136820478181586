import React from 'react';

import './secondaryButton.scss';

const SecondaryButton = ({ onClick, active, activeIcon, inactiveIcon, children }) => {
  return (
    <button onClick={onClick} className={`secondary-button ${active ? 'active' : 'inactive'}`}>
      <img src={activeIcon} style={{ display: active && activeIcon ? 'inline' : 'none' }} alt="" />
      <img src={inactiveIcon} style={{ display: !active && inactiveIcon ? 'inline' : 'none' }} alt="" />
      {children}
    </button>
  );
};

export default SecondaryButton;
