import React from 'react';

import Primary from '../templates/Primary';

import './signUpComplete.scss';

const Profile = () => {
  return (
    <Primary hero="/signupcomplete_hero.jpg">
      <div className="sign-up-complete-container">
        <h1>🙌 Sign up complete</h1>
        <div className="sign-up-complete-content">
          <p>
            You will receive an email shortly containing a link to sign in. If you haven't received to email within one
            hour, please drop us an email at <a href="mailto:support@update.travel">support@update.travel</a>.
          </p>
          <p>Ps. Don't forget to check your spam folder.</p>
        </div>
      </div>
    </Primary>
  );
};

export default Profile;
