import {
  SIGN_IN_EMAIL_SENT,
  SIGN_IN_BEGIN,
  SIGN_IN_FAILURE,
  SIGN_IN_SUCCESS,
  SIGN_OUT_BEGIN,
  SIGN_OUT_FAILURE,
  SIGN_OUT_SUCCESS,
  FETCH_USER_BEGIN,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  TOGGLE_COUNTRY_NOTIFICATIONS_BEGIN,
  TOGGLE_COUNTRY_NOTIFICATIONS_SUCCESS,
  TOGGLE_COUNTRY_NOTIFICATIONS_FAILURE,
} from '../types';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

export const fetchUser = (userId) => {
  return async (dispatch, getState) => {
    if (userId) {
      dispatch({
        type: FETCH_USER_BEGIN,
      });

      const userDoc = await firebase.firestore().collection('users').doc(userId).get();
      if (userDoc.exists) {
        const user = userDoc.data();
        user.id = userDoc.id;

        try {
          const adminDoc = await firebase
            .firestore()
            .collection('users')
            .doc(userId)
            .collection('admin')
            .doc('settings')
            .get();
          if (adminDoc.exists) {
            user.isAdmin = adminDoc.data().isAdmin;
          }
        } catch (error) {}

        dispatch({
          type: FETCH_USER_SUCCESS,
          payload: user,
        });
      } else {
        dispatch({
          type: FETCH_USER_FAILURE,
        });
      }
    } else {
      dispatch({
        type: FETCH_USER_FAILURE,
      });
    }
  };
};

export const sendSignInEmail = (email, path) => {
  return async (dispatch, getState) => {
    const isLocal = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
    firebase.functions().httpsCallable('authentication-emailForSignIn')({ email, path, isLocal });
    window.localStorage.setItem('emailForSignIn', email);
    dispatch({
      type: SIGN_IN_EMAIL_SENT,
    });
  };
};

export const signIn = (email) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SIGN_IN_BEGIN,
    });

    if (getState().user.signedIn) {
      await firebase.auth().signOut();
    }

    firebase
      .auth()
      .signInWithEmailLink(email, window.location.href)
      .then((result) => {
        window.localStorage.removeItem('emailForSignIn');
        dispatch({
          type: SIGN_IN_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: SIGN_IN_FAILURE,
        });
      });
  };
};

export const signOut = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: SIGN_OUT_BEGIN,
    });

    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({
          type: SIGN_OUT_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: SIGN_OUT_FAILURE,
        });
      });
  };
};

export const toggleCountrySubscription = (country) => {
  return async (dispatch, getState) => {
    dispatch({
      type: TOGGLE_COUNTRY_NOTIFICATIONS_BEGIN,
    });

    const {
      user: {
        user: { id, subscriptions: currentSubscriptions },
      },
    } = getState();

    const subscriptions = currentSubscriptions.includes(country)
      ? currentSubscriptions.filter((c) => c !== country)
      : [...currentSubscriptions, country];

    try {
      await firebase.firestore().collection('users').doc(id).update({ subscriptions });
      dispatch({
        type: TOGGLE_COUNTRY_NOTIFICATIONS_SUCCESS,
        payload: subscriptions,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: TOGGLE_COUNTRY_NOTIFICATIONS_FAILURE,
      });
    }
  };
};
