import React from 'react';
import moment from 'moment';

import './footer.scss';

const Footer = (props) => {
  return (
    <footer>
      <img src="/footer_logo.png" alt="" />
    </footer>
  );
};

export default Footer;
