import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Grid, Header, Image, Segment } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';

import { sendSignInEmail } from '../../store/actions';

import './adminSignIn.scss';

const AdminSignIn = (props) => {
  const [email, setEmail] = useState('');
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { signInEmailSent } = useSelector((state) => state.user);

  return (
    <Grid
      className="admin-sign-in-container"
      textAlign="center"
      style={{ height: '100vh', paddingBottom: -148 }}
      verticalAlign="middle"
    >
      <Grid.Column style={{ maxWidth: 450 }}>
        <Image src="/logo_white.png" style={{ marginBottom: 24 }} />
        {signInEmailSent ? (
          <Header>Check your inbox!</Header>
        ) : (
          <Form
            size="large"
            onSubmit={async (event) => {
              event.preventDefault();
              dispatch(sendSignInEmail(email, pathname.slice(1)));
            }}
          >
            <Segment>
              <Form.Input
                value={email}
                onChange={(input) => setEmail(input.target.value)}
                fluid
                icon="user"
                iconPosition="left"
                placeholder="E-mail address"
                autoFocus
              />
              <Button color="black" fluid size="large">
                Sign In
              </Button>
            </Segment>
          </Form>
        )}
      </Grid.Column>
    </Grid>
  );
};

export default AdminSignIn;
