import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import store from './store';
import Router from './Router';

import './normalize.scss';
import './index.scss';
import 'tippy.js/dist/tippy.css';

ReactDOM.render(
  <Provider store={store()}>
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
