import React from 'react';
import { ResponsiveContainer, LineChart, Line, Tooltip } from 'recharts';
import AnimatedNumber from 'react-animated-number';
import moment from 'moment';

import Translate from '../utilities/Translate';
import useWindowDimensions from '../utilities/useWindowDimensions';

import './countryInfo.scss';

const CountryInfo = ({ advisory, covid }) => {
  const { width } = useWindowDimensions();
  const covidChartData = covid.list
    .slice(0, 30)
    .reverse()
    .map((cases) => ({ cases }));

  const formatNumber = (number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return (
    <div className="country-info">
      <div className="country-info-column">
        <img src="/virus_white.png" alt="" />
        <div className="country-info-sub-column">
          <ResponsiveContainer width="90%" height={100}>
            <LineChart data={covidChartData}>
              <Tooltip
                formatter={(value, name, props) => `${value} cases`}
                content={(c) => (
                  <div className="country-info-covid-tooltip">
                    <h5>{covidChartData[c.label] ? formatNumber(covidChartData[c.label].cases) : 0} cases</h5>
                    {moment()
                      .subtract(30 - c.label, 'days')
                      .format('D MMMM')}
                  </div>
                )}
              />
              <Line type="monotone" dot={false} dataKey="cases" stroke="#fff" strokeWidth={2} />
            </LineChart>
          </ResponsiveContainer>
          <div className="country-info-covid-info">
            <Translate>Covid-19 cases reported last month</Translate>
          </div>
        </div>
      </div>
      <div className="country-info-column-divider" />
      <div className="country-info-column">
        <img src="/face_mask.png" alt="" />
        <div className="country-info-covid">
          <div>
            <h3>
              <AnimatedNumber
                component="span"
                value={covid.cases}
                duration={360}
                stepPrecision={0}
                formatValue={formatNumber}
              />
            </h3>
            <Translate>Total cases</Translate>
          </div>
          <div>
            <h3>
              <AnimatedNumber
                component="span"
                value={covid.deaths}
                duration={360}
                stepPrecision={0}
                formatValue={formatNumber}
              />
            </h3>
            <Translate>Total deaths</Translate>
          </div>
        </div>
      </div>
      <div className="country-info-column-divider" />
      <div className="country-info-column">
        <img src="/warning_sign_white.png" alt="" />
        <div className="country-info-risk-score">
          <div>
            <Translate>Risk index</Translate>:{' '}
            <span className="risk-score">
              <AnimatedNumber component="span" value={advisory.score} duration={360} stepPrecision={1} />
            </span>{' '}
            <Translate>out of</Translate> 5.0
            <p>
              <a href={advisory.source} target="_blank" rel="noreferrer noopener">
                <Translate>Source</Translate>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountryInfo;
