import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import Translate from '../utilities/Translate';
import useTranslation from '../utilities/useTranslation';
import useValidateEmail from '../utilities/useValidateEmail';

import { setLanguage, sendSignInEmail, toggleMenu } from '../../store/actions';

import './topMenu.scss';

const TopMenu = () => {
  const [signIn, setSignIn] = useState(false);
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const dispatch = useDispatch();
  const {
    app: { menuOpen },
    locale: { active },
    user: {
      signedIn,
      signInEmailSent,
      user: { name, isAdmin },
    },
  } = useSelector((state) => state);
  const { pathname } = useLocation();
  const translation = useTranslation();
  const validateEmail = useValidateEmail();

  return (
    <div className="top-menu">
      <div className={`top-menu-mobile ${menuOpen ? 'open' : 'closed'}`}>
        <div className="top-menu-content">
          <NavLink to="/" exact activeClassName="top-menu-active" onClick={() => dispatch(toggleMenu())}>
            <Translate>Where can I go?</Translate>
          </NavLink>
          <NavLink to="/articles" activeClassName="top-menu-active" onClick={() => dispatch(toggleMenu())}>
            <Translate>News & Guides</Translate>
          </NavLink>
          <NavLink to="/about" activeClassName="top-menu-active" onClick={() => dispatch(toggleMenu())}>
            <Translate>About</Translate>
          </NavLink>
          {signedIn ? (
            <NavLink to="/profile" activeClassName="top-menu-active" onClick={() => dispatch(toggleMenu())}>
              <Translate>My profile</Translate>
            </NavLink>
          ) : (
            <NavLink to="/signup" activeClassName="top-menu-active" onClick={() => dispatch(toggleMenu())}>
              <Translate>Sign up for Premium</Translate>
            </NavLink>
          )}
        </div>
        <div className="top-menu-footer">
          {signedIn ? (
            <div className="top-menu-user-container">
              <Link to="/profile" onClick={() => dispatch(toggleMenu())}>
                <Icon name="user outline" /> {name}
              </Link>
            </div>
          ) : (
            <>
              <div className="top-menu-sign-in-container">
                <button
                  onClick={() => {
                    if (!signInEmailSent) {
                      setSignIn(!signIn);
                    }
                  }}
                >
                  <img src="/user.png" alt="" />
                  {!signIn && <Translate>Sign In</Translate>}
                </button>
                {signInEmailSent && (
                  <div className="top-menu-signing-in">
                    <Translate>Check your email!</Translate>
                  </div>
                )}
                {signIn && !signInEmailSent && (
                  <form
                    onSubmit={(event) => {
                      event.preventDefault();

                      dispatch(sendSignInEmail(email, pathname.slice(1)));
                    }}
                    className="top-menu-sign-in"
                  >
                    <input
                      value={email}
                      onChange={(input) => {
                        setEmail(input.target.value.toLowerCase());
                        setValidEmail(validateEmail(input.target.value.toLowerCase()));
                      }}
                      autoFocus
                      placeholder="Email"
                      placeholder={translation('Email')}
                    />
                    <button type="submit" disabled={!validEmail}>
                      <img src="/sign_in_plane.png" alt="" />
                    </button>
                  </form>
                )}
              </div>
            </>
          )}
        </div>
        {!signIn && !signInEmailSent && (
          <div className="top-menu-choose-language">
            <span
              onClick={() => {
                dispatch(setLanguage('en'));
                localStorage.setItem('language', 'en');
              }}
              style={{ fontWeight: active === 'en' ? 'bold' : 'normal' }}
            >
              EN
            </span>{' '}
            |{' '}
            <span
              onClick={() => {
                dispatch(setLanguage('sv'));
                localStorage.setItem('language', 'sv');
              }}
              style={{ fontWeight: active === 'sv' ? 'bold' : 'normal' }}
            >
              SV
            </span>{' '}
            |{' '}
            <span
              onClick={() => {
                dispatch(setLanguage('vi'));
                localStorage.setItem('language', 'vi');
              }}
              style={{ fontWeight: active === 'vi' ? 'bold' : 'normal' }}
            >
              VI
            </span>
          </div>
        )}

        <div className={`arrow ${menuOpen ? 'open' : 'closed'}`} style={{ top: isAdmin ? 124 : 88 }} />
      </div>
      <div className="top-menu-desktop">
        <NavLink to="/" exact activeClassName="top-menu-active">
          <Translate>Where can I go?</Translate>
        </NavLink>
        <NavLink to="/articles" activeClassName="top-menu-active">
          <Translate>News & Guides</Translate>
        </NavLink>
        <NavLink to="/about" activeClassName="top-menu-active">
          <Translate>About</Translate>
        </NavLink>
        {signedIn ? (
          <NavLink to="/profile" activeClassName="top-menu-active">
            <Translate>My profile</Translate>
          </NavLink>
        ) : (
          <NavLink to="/signup" activeClassName="top-menu-active">
            <Translate>Sign up</Translate>
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default TopMenu;
