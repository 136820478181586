import React from 'react';
import { useSelector } from 'react-redux';
import Translate from '../utilities/Translate';
import { Switch, Route, useParams } from 'react-router-dom';

import Hero from './Hero';
import TravelFrom from './TravelFrom';
import Country from '../country/Country';
import HeaderDivider from '../templates/HeaderDivider';
import Updates from './updates/Updates';

import ArticleCard from '../editorial/ArticleCard';

import './home.scss';

const Home = () => {
  const { country } = useParams();
  const { articles } = useSelector((state) => state.articles);

  return (
    <div className="home-container">
      <Hero country={country || false} />
      <div className="home-content-container">
        <div className="home-content">
          <Switch>
            <Route path="/country/:country" component={Country} />
            <Route path="/" component={TravelFrom} />
          </Switch>
        </div>
        <HeaderDivider>
          <Translate>Tips for the traveller</Translate>
        </HeaderDivider>
        <div className="home-content-articles">
          {articles.map(({ id, header, image }, index) => (
            <ArticleCard key={`article-${index}`} to={`/articles/${id}`} img={image} title={header} />
          ))}
        </div>
        <Updates />
      </div>
    </div>
  );
};

export default Home;
