import React from 'react';
import { useSelector } from 'react-redux';
import useTranslation from '../utilities/useTranslation';
import Primary from '../templates/Primary';
import ArticleCard from './ArticleCard';
import Sidebar from './Sidebar';

import './editorial.scss';

const Editorial = () => {
  const { articles } = useSelector((state) => state.articles);
  const translation = useTranslation();

  return (
    <Primary title={translation('News & Guides')} hero="news_hero_background.jpg">
      <div className="editorial-content">
        <div className="editorial-ad">
          <Sidebar />
        </div>

        {articles.map(({ id, header, image }, index) => (
          <ArticleCard key={`article-${index}`} to={`/articles/${id}`} img={image} title={header} />
        ))}
      </div>
    </Primary>
  );
};

export default Editorial;
