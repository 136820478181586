import countries from './countries';
import articles from './articles';
import country from './country';
import user from './user';
import locale from './locale';
import admin from './admin';
import app from './app';

import { combineReducers } from 'redux';

export default combineReducers({
  countries,
  articles,
  country,
  user,
  locale,
  admin,
  app,
});
