import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, Dropdown, Image, Icon } from 'semantic-ui-react';
import { Link, useLocation } from 'react-router-dom';

import { signOut } from '../../store/actions';

import './adminTopMenu.scss';

const AdminTopMenu = (props) => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const active = (location.pathname.split('/').length >= 2 && location.pathname.split('/')[2]) || '';

  return (
    <Menu fixed="top" inverted>
      <Menu.Item as={Link} to="/" header>
        <Image size="small" src="/logo_white.png" />
      </Menu.Item>
      {[
        {
          path: '/admin',
          key: '',
          title: 'Dashboard',
          icon: 'dashboard',
        },
        {
          path: '/admin/countries',
          key: 'countries',
          title: 'Countries',
          icon: 'globe',
        },
        {
          path: '/admin/articles',
          key: 'articles',
          title: 'Articles',
          icon: 'newspaper outline',
        },
        {
          path: '/admin/users',
          key: 'users',
          title: 'Users',
          icon: 'users',
        },
        {
          path: '/admin/finance',
          key: 'finance',
          title: 'Finance',
          icon: 'payment',
        },
      ].map(({ path, key, title, icon }, index) => (
        <Menu.Item
          key={`admin-top-menu-${index}`}
          as={Link}
          to={path}
          active={active === key}
          onClick={() => window.scrollTo(0, 0)}
          style={{ backgroundColor: active === key ? '#fff' : 'inherit' }}
        >
          <div style={{ color: active === key ? '#000' : '#fff' }}>
            <Icon name={icon} /> {title}
          </div>
        </Menu.Item>
      ))}
      <Menu.Menu position="right">
        <Dropdown item simple text={user.name}>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => dispatch(signOut())}>
              Sign out <Icon name="sign out" />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    </Menu>
  );
};

export default AdminTopMenu;
