import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';

import Translate from '../Translate';
import useTranslation from '../useTranslation';
import useWindowDimensions from '../useWindowDimensions';
import {
  setDepartureCountry,
  setDepartureDisplay,
  setDestinationDisplay,
  setDepartureFilters,
  setDestinationFilters,
  toggleCountrySubscription,
} from '../../../store/actions';
import Tippy from '@tippyjs/react';

import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';

import './mapFilters.scss';

const MapFilters = ({ isDeparture, showFilters }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    app: {
      departureCountry,
      destinationCountry,
      departureDisplay,
      destinationDisplay,
      departureFilters,
      destinationFilters,
    },
    countries: { list },
    country: { id },
    user: {
      signedIn,
      loadingCountrySubscriptionToggle,
      user: { subscriptions },
    },
  } = useSelector((state) => state);
  const translation = useTranslation();
  const filters = isDeparture ? departureFilters : destinationFilters;
  const setFilters = isDeparture ? setDepartureFilters : setDestinationFilters;
  const allFilters = ['open', 'restricted', 'closed'];
  const allVisible = allFilters.reduce((acc, next) => acc && filters.includes(next), true);
  const { width } = useWindowDimensions();

  const selected = isDeparture ? departureCountry : destinationCountry;
  if (!departureCountry || !list[selected] || !Object.keys(list).length) {
    return null;
  }

  const isSubscribed = subscriptions && subscriptions.includes(id);
  const subscriptionEnabled = list[id] && list[id].subscriptionEnabled;

  return (
    <div className="map-filters">
      <div className="map-filters-status">
        <div
          className="map-filter-departure-country"
          style={{
            width: width <= 768 ? width - 18 : `${7 * list[selected].name.length + (isDeparture ? 180 : 260)}px`,
          }}
        >
          <Select
            value={{
              label: (
                <div className="map-filters-country-option">
                  <>
                    <img src="/location.png" alt="" />
                    <Translate>{isDeparture ? 'Travel from' : 'Countries accepted by'}</Translate>:{' '}
                    <Translate>{list[selected].name}</Translate>
                  </>
                </div>
              ),
            }}
            onChange={({ value }) => {
              if (isDeparture) {
                dispatch(setDepartureCountry(value));
              } else {
                history.push(`/country/${value}`);
              }
            }}
            noOptionsMessage={() => <Translate>No matches...</Translate>}
            placeholder={
              <div className="main-header-choose-country-placeholder">
                <img src="/location.png" alt="" />
                <Translate>Choose country</Translate>
              </div>
            }
            styles={{
              control: (provided, state) => ({
                ...provided,
                background: '#fff',
                border: 'none',
              }),
              indicatorSeparator: (provided, state) => ({
                ...provided,
                display: 'none',
                width: 0,
              }),
              option: (provided, state) => ({
                ...provided,
                color: '#000',
              }),
              menuList: (provided, state) => ({
                ...provided,
                background: '#fff',
                minWidth: 200,
                borderRadius: 8,
              }),
            }}
            theme={{
              borderRadius: 8,
              colors: {
                neutral0: '#ffffff',
                primary25: '#fff0d7',
                primary50: '#e0b163',
              },
              spacing: { baseUnit: 4, controlHeight: 38, menuGutter: 0 },
            }}
            filterOption={({ data: { name } }, search) => {
              return name.includes(search.toLowerCase());
            }}
            options={Object.entries(list)
              .map(([key, { name }]) => ({ key, country: name }))
              .sort(({ country: a }, { country: b }) => (translation(a) < translation(b) ? -1 : 1))
              .map(({ key, country }) => ({
                value: key,
                name: translation(country).toLowerCase(),
                label: <div>{translation(country)}</div>,
              }))}
          />
        </div>
        {!isDeparture && (
          <Tippy
            content={
              <span>
                <Translate>
                  {subscriptionEnabled ? 'Subscribe to updates' : 'This country is not available for subscriptions yet'}
                </Translate>
              </span>
            }
          >
            <div>
              <PrimaryButton
                disabled={!subscriptionEnabled}
                active={isSubscribed}
                loading={loadingCountrySubscriptionToggle}
                image={isSubscribed ? '/notification_bell_white_filled.png' : '/notification_bell_white.png'}
                onClick={() => (signedIn ? dispatch(toggleCountrySubscription(id)) : history.push('/signup'))}
              >
                {isSubscribed
                  ? `${translation('Unsubscribe from')} ${list[destinationCountry].name}`
                  : subscriptionEnabled
                  ? translation('Subscribe to updates')
                  : translation('Not available for subscription yet')}
              </PrimaryButton>
            </div>
          </Tippy>
        )}
        {showFilters && (
          <>
            <SecondaryButton active={allVisible} onClick={() => dispatch(setFilters(allFilters))}>
              <Translate>Show all</Translate>
            </SecondaryButton>
            {['Open', 'Restricted', 'Closed'].map((filter) => (
              <SecondaryButton
                key={filter}
                active={filters.includes(filter.toLowerCase())}
                onClick={() =>
                  dispatch(
                    setFilters(
                      filters.includes(filter.toLowerCase())
                        ? filters.filter((f) => f !== filter.toLowerCase())
                        : [...filters, filter.toLowerCase()]
                    )
                  )
                }
              >
                <Translate>{filter}</Translate>
              </SecondaryButton>
            ))}
          </>
        )}
      </div>
      <div className="map-filters-display">
        <SecondaryButton
          active={isDeparture ? departureDisplay === 'map' : destinationDisplay === 'map'}
          onClick={() => (isDeparture ? dispatch(setDepartureDisplay('map')) : dispatch(setDestinationDisplay('map')))}
          activeIcon="/globe_white.png"
          inactiveIcon="/globe_black.png"
        >
          <Translate>Map</Translate>
        </SecondaryButton>
        <SecondaryButton
          active={isDeparture ? departureDisplay === 'list' : destinationDisplay === 'list'}
          onClick={() =>
            isDeparture ? dispatch(setDepartureDisplay('list')) : dispatch(setDestinationDisplay('list'))
          }
          activeIcon="/list_white.png"
          inactiveIcon="/list_black.png"
        >
          <Translate>List</Translate>
        </SecondaryButton>
      </div>
    </div>
  );
};

export default MapFilters;
