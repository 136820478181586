import { useSelector } from 'react-redux';

export default () => {
  const translator = useSelector((state) => state.locale.translations[state.locale.active]);

  return (translate) => {
    if (translator[translate] || translator.countries[translate]) {
      return translator[translate] || translator.countries[translate];
    }
    return translate;
  };
};
