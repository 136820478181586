import {
  SIGN_IN_EMAIL_SENT,
  SIGN_IN_BEGIN,
  SIGN_IN_FAILURE,
  SIGN_IN_SUCCESS,
  SIGN_OUT_BEGIN,
  SIGN_OUT_FAILURE,
  SIGN_OUT_SUCCESS,
  FETCH_USER_BEGIN,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  TOGGLE_COUNTRY_NOTIFICATIONS_BEGIN,
  TOGGLE_COUNTRY_NOTIFICATIONS_SUCCESS,
  TOGGLE_COUNTRY_NOTIFICATIONS_FAILURE,
} from '../types';

const INITIAL_STATE = {
  signInEmailSent: false,
  signedIn: false,
  signingIn: true,
  user: {},
  loadingCountrySubscriptionToggle: false,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SIGN_IN_EMAIL_SENT:
      return {
        ...state,
        signInEmailSent: true,
      };
    case SIGN_IN_BEGIN:
      return state;
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        signedIn: true,
      };
    case SIGN_IN_FAILURE:
      return {
        ...state,
        signingIn: false,
      };
    case FETCH_USER_BEGIN:
      return state;
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        user: payload,
        signingIn: false,
        signedIn: true,
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        signingIn: false,
      };
    case SIGN_OUT_BEGIN:
      return state;
    case SIGN_OUT_FAILURE:
      return state;
    case SIGN_OUT_SUCCESS:
      return INITIAL_STATE;
    case TOGGLE_COUNTRY_NOTIFICATIONS_BEGIN:
      return { ...state, loadingCountrySubscriptionToggle: true };
    case TOGGLE_COUNTRY_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          subscriptions: payload,
        },
        loadingCountrySubscriptionToggle: false,
      };
    case TOGGLE_COUNTRY_NOTIFICATIONS_FAILURE:
      return { ...state, loadingCountrySubscriptionToggle: false };
    default:
      return state;
  }
};
