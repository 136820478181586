import React from 'react';
import Translate from '../utilities/Translate';

import './headerDivider.scss';

const HeaderDivider = ({ children }) => {
  return (
    <div className="header-divider">
      <h3>
        <Translate>{children}</Translate>
      </h3>
    </div>
  );
};

export default HeaderDivider;
