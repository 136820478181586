import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Segment, Input, Image, Card, Loader, Icon } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';

const AdminArticleImage = ({ image, setImage }) => {
  const [uploadingImage, setUploadingImage] = useState(false);

  const onDrop = async (files, fileRejections, event) => {
    if (files.length === 0) {
      return;
    }
    const fileEnding = files[0].name.split('.')[1].toLowerCase();

    if (!['jpg', 'jpeg', 'png'].includes(fileEnding)) {
      return;
    }

    setUploadingImage(true);
    const id = uuidv4();
    await firebase.storage().ref(`/articleImages/${id}.${fileEnding}`).put(files[0]);
    const image = await firebase.storage().ref(`/articleImages/${id}.${fileEnding}`).getDownloadURL();
    setImage(image);
    setUploadingImage(false);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return image ? (
    <div style={{ display: 'flex', justifyContent: 'center' }} {...getRootProps()}>
      <Image src={image} style={{ borderRadius: 4, height: '100%' }} fluid />
      <input
        type="file"
        style={{ opacity: 0, position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
        {...getInputProps()}
      />
    </div>
  ) : (
    <div
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: 120 }}
      {...getRootProps()}
    >
      {isDragActive ? (
        <span>Drop it like it's hot!</span>
      ) : uploadingImage ? (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Loader style={{ width: '100%' }} inline active />
          <span style={{ marginTop: 8 }}>Uploading...</span>
        </div>
      ) : (
        <Icon style={{ width: '100%' }} name="upload" size="large" />
      )}
      <input
        type="file"
        style={{ opacity: 0, position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
        {...getInputProps()}
      />
    </div>
  );
};

export default AdminArticleImage;
