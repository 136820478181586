import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';

import MapFilters from '../utilities/mapFilters/MapFilters';
import MapLegend from '../utilities/mapLegend/MapLegend';
import CountryInfo from './CountryInfo';

import CountryHeader from './CountryHeader';
import CountryRestrictionsList from '../utilities/countryRestrictionsList/CountryRestrictionsList';
import useWindowDimensions from '../utilities/useWindowDimensions';
import getCountryColor from '../utilities/getCountryColor';

import countries from '../../countries.json';
import { fetchCountry, setDestinationCountry, setHoverDestinationCountry } from '../../store/actions';

import './country.scss';

const Country = () => {
  const dispatch = useDispatch();
  const { country } = useParams();
  const {
    country: { loading, id, name, active, restrictions, advisory, covid },
    countries: { list },
    app: { firebaseInitialized, departureCountry, destinationFilters, destinationDisplay },
  } = useSelector((state) => state);
  const [firstLoad, setFirstLoad] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (firebaseInitialized && country !== id) {
      dispatch(setDestinationCountry(country));
      dispatch(fetchCountry(country));
    }

    if (country === id) {
      setFirstLoad(false);
    }
  }, [dispatch, firebaseInitialized, country, loading]);

  const { width } = useWindowDimensions();
  const height = width < 768 ? width - 260 : width >= 1200 ? 580 : 580 - (1200 - width) / 1.2;

  const getTravelStatusForDepartureCountry = () => {
    if (restrictions.closed.includes(departureCountry)) {
      return 'closed';
    } else if (restrictions.restricted.includes(departureCountry)) {
      return 'restricted';
    } else if (restrictions.open.includes(departureCountry)) {
      return 'open';
    } else {
      return 'unknown';
    }
  };

  if ((firstLoad && loading) || !list[departureCountry]) {
    return (
      <div className="country-loader">
        <Loader active inline size="huge" />
      </div>
    );
  }

  return (
    <>
      <CountryHeader
        country={name}
        flag={id}
        travelStatus={getTravelStatusForDepartureCountry()}
        lastUpdate={new Date()}
        hero={`country_hero/${id}.jpg`}
      />
      <div className="country">
        <div className="country-content">
          <MapFilters />
          <div className="country-map">
            {destinationDisplay === 'map' ? (
              <>
                <ComposableMap
                  projection="geoMercator"
                  tyle={{
                    width: '100%',
                    height: width >= 1200 ? 780 : width >= 400 ? 780 - (1200 - width) / 1.2 : 230,
                  }}
                  projectionConfig={{
                    scale: width >= 1200 ? 130 : width >= 400 ? 130 + (1200 - width) / 10 : 140,
                    center: [8, 44],
                  }}
                >
                  <Geographies geography={countries}>
                    {({ geographies }) =>
                      geographies.map((geo) => {
                        return (
                          <Geography
                            onClick={() => {
                              if (geo.properties.ISO_A2.length !== 2) {
                                return;
                              }
                              dispatch(setDestinationCountry(geo.properties.ISO_A2.toLowerCase()));
                              history.push(`/country/${geo.properties.ISO_A2.toLowerCase()}`);
                            }}
                            key={geo.rsmKey}
                            geography={geo}
                            onMouseLeave={() => dispatch(setHoverDestinationCountry(''))}
                            onMouseEnter={() =>
                              dispatch(setHoverDestinationCountry(geo.properties.ISO_A2.toLowerCase()))
                            }
                            style={{
                              default: {
                                fill: getCountryColor(
                                  id,
                                  geo.properties.ISO_A2.toLowerCase(),
                                  destinationFilters,
                                  restrictions
                                ),
                                stroke: geo.properties.ISO_A2.toLowerCase() === id ? '#444' : '#e4e4e4',
                                strokeWidth: 0.5,
                                outline: 'none',
                                transition: 'all 250ms',
                              },
                              hover: {
                                fill: getCountryColor(
                                  id,
                                  geo.properties.ISO_A2.toLowerCase(),
                                  destinationFilters,
                                  restrictions
                                ),
                                stroke: geo.properties.ISO_A2.toLowerCase() === id ? '#e29101' : '#e4e4e4',
                                opacity: 0.8,
                                strokeWidth: 0.75,
                                outline: 'none',
                                transition: 'all 250ms',
                                cursor: 'pointer',
                              },
                              pressed: {
                                fill: getCountryColor(
                                  id,
                                  geo.properties.ISO_A2.toLowerCase(),
                                  destinationFilters,
                                  restrictions
                                ),
                                stroke: geo.properties.ISO_A2.toLowerCase() === id ? '#e29101' : '#e4e4e4',
                                opacity: 1,
                                strokeWidth: 0.75,
                                outline: 'none',
                                transition: 'all 250ms',
                                cursor: 'pointer',
                              },
                            }}
                          />
                        );
                      })
                    }
                  </Geographies>
                </ComposableMap>
              </>
            ) : (
              <CountryRestrictionsList restrictions={restrictions} />
            )}
          </div>
          <div className="country-footer">
            {destinationDisplay === 'map' && <MapLegend />}
            <CountryInfo advisory={advisory} covid={covid} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Country;
