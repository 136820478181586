import React from 'react';
import Translate from '../utilities/Translate';

import './sidebar.scss';

const Sidebar = () => {
  return (
    <div className="sidebar">
      <h3>Bästa resekreditkorten</h3>
      <div className="sidebar-content">
        <img src="/amex_sas_elite.png" alt="" />
        <h3>American Express SAS Elite</h3>
        <p>För dig som vill få högst poängintjäning och en omfattande reseförsäkring.</p>
        <button>
          <Translate>Apply today</Translate>
        </button>
      </div>
      <div className="sidebar-divider" />
      <div className="sidebar-content">
        <img src="/amex_sas_premium.png" alt="" />
        <h3>American Express SAS Premium</h3>
        <p>Passar dig som vill ha ett omfattande kreditkort med generös reseförsäkring.</p>
        <button>
          <Translate>Apply today</Translate>
        </button>
      </div>
      <div className="sidebar-divider" />
      <div className="sidebar-content">
        <img src="/sas_mastercard.png" alt="" />
        <h3>SAS EuroBonus World Mastercard</h3>
        <p>Ett Mastercard som ger fina förmåner i SAS Eurobonus systemet.</p>
        <button>
          <Translate>Apply today</Translate>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
