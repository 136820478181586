import React from 'react';
import { motion } from 'framer-motion';

import Translate from '../utilities/Translate';

const PremiumFeatures = () => {
  const premiumFeatures = [
    {
      text: '',
      firstColumn: <Translate>Free</Translate>,
      secondColumn: (
        <>
          <img src="notification_bell_white.png" alt="" />
          <Translate>Premium</Translate>
        </>
      ),
    },
    {
      text: <Translate>Travel restriction information on the website</Translate>,
      firstColumn: <img src="checkbox_orange.png" alt="Included" />,
      secondColumn: <img src="checkbox_orange.png" alt="Included" />,
    },
    {
      text: <Translate>Country information and news articles</Translate>,
      firstColumn: <img src="checkbox_orange.png" alt="Included" />,
      secondColumn: <img src="checkbox_orange.png" alt="Included" />,
    },
    {
      text: <Translate>Email updates when travel restrictions gets updated</Translate>,
      firstColumn: '',
      secondColumn: <img src="checkbox_orange.png" alt="Included" />,
    },
    {
      text: <Translate>Real time coverage for your selected countries</Translate>,
      firstColumn: '',
      secondColumn: <img src="checkbox_orange.png" alt="Included" />,
    },
    {
      text: <Translate>Support the future development of update.travel</Translate>,
      firstColumn: '',
      secondColumn: <img src="checkbox_orange.png" alt="Included" />,
    },
    {
      text: '',
      firstColumn: '',
      secondColumn: (
        <>
          <h4>€3</h4> / <Translate>month</Translate>
        </>
      ),
    },
  ];

  return (
    <div className="sign-up-premium">
      <h2>
        <Translate>Get premium today!</Translate>
      </h2>
      <p>
        <Translate>Get premium info</Translate>
      </p>

      <motion.div
        className="sign-up-premium-checklist"
        variants={{
          hidden: { opacity: 1, scale: 0 },
          visible: {
            opacity: 1,
            scale: 1,
            transition: {
              delay: 0.3,
              when: 'beforeChildren',
              staggerChildren: 0.15,
            },
          },
        }}
        initial="hidden"
        animate="visible"
      >
        {premiumFeatures.map((feature, index) => (
          <motion.div
            key={index}
            className="sign-up-premium-checklist-row"
            variants={{
              hidden: { y: 20, opacity: 0 },
              visible: {
                y: 0,
                opacity: 1,
              },
            }}
          >
            <div>{feature.text}</div>
            <div
              className={index === 0 || index === premiumFeatures.length - 1 ? 'sign-up-premium-checklist-header' : ''}
            >
              {feature.firstColumn}
            </div>
            <div
              className={index === 0 || index === premiumFeatures.length - 1 ? 'sign-up-premium-checklist-header' : ''}
            >
              {feature.secondColumn}
            </div>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default PremiumFeatures;
