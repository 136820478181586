import React from 'react';

import './checkbox.scss';

const Checkox = ({ checked, onChange, children }) => {
  return (
    <label className="checkbox">
      <input type="checkbox" onChange={onChange} checked={checked || false} />
      <div>
        <span>{children}</span>
      </div>
    </label>
  );
};

export default Checkox;
