import React from 'react';
import { Link } from 'react-router-dom';

import './articleCard.scss';

const ArticleCard = ({ title, img, to }) => {
  return (
    <Link className="article-card" to={to}>
      <div>
        <div className="article-card-image-container">{img && <img src={img} alt="" />}</div>
        <h3>{title}</h3>
      </div>
      <span className="article-card-read-more">+</span>
    </Link>
  );
};

export default ArticleCard;
