import React from 'react';
import { useScrollYPosition } from 'react-use-scroll-position';

import './primary.scss';

const Primary = ({ title, hero, children }) => {
  const scrollY = useScrollYPosition();

  return (
    <div className="primary-container">
      <div
        className="primary-hero"
        style={{ backgroundImage: `url(${hero})`, backgroundSize: `${112 + scrollY / 30}%` }}
      >
        {title && (
          <div className="primary-hero-title-background">
            <div className="primary-hero-title">
              <h1>{title}</h1>
            </div>
          </div>
        )}
      </div>
      <div className="primary-content-container">
        <div className="primary-content">{children}</div>
      </div>
    </div>
  );
};

export default Primary;
