import supportedLocales from '../../translations/supportedLocales';
import en from '../../translations/en.json';
import sv from '../../translations/sv.json';
import vi from '../../translations/vi.json';

import { SET_LANGUAGE } from '../types';

const INITIAL_STATE = {
  active: 'sv',
  translations: {
    en,
    sv,
    vi,
  },
  supportedLocales,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_LANGUAGE:
      return {
        ...state,
        active: payload,
      };
    default:
      return state;
  }
};
