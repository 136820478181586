import { FETCH_ARTICLES } from '../types';

const INITIAL_STATE = {
  articles: [],
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_ARTICLES:
      return {
        ...state,
        articles: payload,
      };
    default:
      return state;
  }
};
