import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import {
  Button,
  Card,
  Checkbox,
  Dropdown,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  List,
  ListItem,
  Loader,
  Divider,
} from 'semantic-ui-react';
import moment from 'moment';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import countries from '../../../countries.json';
import * as firebase from 'firebase/app';
import 'firebase/functions';

import { fetchCountry, updateCountry, toggleAcknowledgeScraper } from '../../../store/actions';

import './countryDetails.scss';

const CountryDetails = ({ id }) => {
  const [values, setValues] = useState([]);
  const [active, setActive] = useState(false);
  const [restrictions, setRestrictions] = useState({ unknown: [], closed: [], restricted: [], open: [] });
  const [isEditing, setIsEditing] = useState(false);
  const [manualCountryList, setManualCountryList] = useState('');
  const [scraping, setScraping] = useState(false);

  const {
    country: {
      loading,
      loadingScraperToggle,
      name,
      active: currentActive,
      restrictions: currentRestrictions,
      scrapers,
      isSaving,
    },
    countries: { list },
  } = useSelector((state) => state);

  useEffect(() => {
    setRestrictions(currentRestrictions);
    setActive(currentActive);
  }, [currentRestrictions, currentActive]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCountry(id));
    setValues([]);
    window.scrollTo(0, 0);
  }, [id, dispatch]);

  if (loading) {
    return <Loader active size="massive" />;
  }

  const continents = ['Europe', 'North America', 'Asia', 'Africa', 'South America', 'Oceania'];
  const areas = {
    Schengen: [
      { key: 'at', text: 'Austria' },
      { key: 'be', text: 'Belgium' },
      { key: 'cz', text: 'Czech Republic' },
      { key: 'dk', text: 'Denmark' },
      { key: 'ee', text: 'Estonia' },
      { key: 'fi', text: 'Finland' },
      { key: 'fr', text: 'France' },
      { key: 'de', text: 'Germany' },
      { key: 'gr', text: 'Greece' },
      { key: 'hu', text: 'Hungary' },
      { key: 'is', text: 'Iceland' },
      { key: 'it', text: 'Italy' },
      { key: 'lv', text: 'Latvia' },
      { key: 'li', text: 'Liechtenstein' },
      { key: 'lt', text: 'Lithuania' },
      { key: 'lu', text: 'Luxembourg' },
      { key: 'mt', text: 'Malta' },
      { key: 'nl', text: 'Netherlands' },
      { key: 'no', text: 'Norway' },
      { key: 'pl', text: 'Poland' },
      { key: 'pt', text: 'Portugal' },
      { key: 'sk', text: 'Slovakia' },
      { key: 'si', text: 'Slovenia' },
      { key: 'es', text: 'Spain' },
      { key: 'se', text: 'Sweden' },
      { key: 'ch', text: 'Switzerland' },
    ],
    EU: [
      { key: 'at', text: 'Austria' },
      { key: 'be', text: 'Belgium' },
      { key: 'bg', text: 'Bulgaria' },
      { key: 'cy', text: 'Cyprus' },
      { key: 'cz', text: 'Czech Republic' },
      { key: 'dk', text: 'Denmark' },
      { key: 'ee', text: 'Estonia' },
      { key: 'fi', text: 'Finland' },
      { key: 'fr', text: 'France' },
      { key: 'de', text: 'Germany' },
      { key: 'gr', text: 'Greece' },
      { key: 'hu', text: 'Hungary' },
      { key: 'it', text: 'Italy' },
      { key: 'lv', text: 'Latvia' },
      { key: 'lt', text: 'Lithuania' },
      { key: 'lu', text: 'Luxembourg' },
      { key: 'mt', text: 'Malta' },
      { key: 'nl', text: 'Netherlands' },
      { key: 'pl', text: 'Poland' },
      { key: 'pt', text: 'Portugal' },
      { key: 'ro', text: 'Romania' },
      { key: 'sk', text: 'Slovakia' },
      { key: 'si', text: 'Slovenia' },
      { key: 'es', text: 'Spain' },
      { key: 'se', text: 'Sweden' },
    ],
    EAA: [
      { key: 'at', text: 'Austria' },
      { key: 'be', text: 'Belgium' },
      { key: 'bg', text: 'Bulgaria' },
      { key: 'cy', text: 'Cyprus' },
      { key: 'cz', text: 'Czech Republic' },
      { key: 'dk', text: 'Denmark' },
      { key: 'ee', text: 'Estonia' },
      { key: 'fi', text: 'Finland' },
      { key: 'fr', text: 'France' },
      { key: 'de', text: 'Germany' },
      { key: 'gr', text: 'Greece' },
      { key: 'hu', text: 'Hungary' },
      { key: 'is', text: 'Iceland' },
      { key: 'it', text: 'Italy' },
      { key: 'lv', text: 'Latvia' },
      { key: 'li', text: 'Liechtenstein' },
      { key: 'lt', text: 'Lithuania' },
      { key: 'lu', text: 'Luxembourg' },
      { key: 'mt', text: 'Malta' },
      { key: 'nl', text: 'Netherlands' },
      { key: 'no', text: 'Norway' },
      { key: 'pl', text: 'Poland' },
      { key: 'pt', text: 'Portugal' },
      { key: 'ro', text: 'Romania' },
      { key: 'sk', text: 'Slovakia' },
      { key: 'si', text: 'Slovenia' },
      { key: 'es', text: 'Spain' },
      { key: 'se', text: 'Sweden' },
    ],
    ASEAN: [
      { key: 'bn', text: 'Brunei' },
      { key: 'kh', text: 'Cambodia' },
      { key: 'tl', text: 'East Timor' },
      { key: 'id', text: 'Indonesia' },
      { key: 'la', text: 'Laos' },
      { key: 'my', text: 'Malaysia' },
      { key: 'mm', text: 'Myanmar' },
      { key: 'ph', text: 'Philippines' },
      { key: 'sg', text: 'Singapore' },
      { key: 'th', text: 'Thailand' },
      { key: 'vn', text: 'Vietnam' },
    ],
  };

  const countryOptions = [
    {
      key: 'all',
      content: 'All countries',
    },
    {
      key: 'divider1',
      content: <hr style={{ height: 1, margin: 0, padding: 0, border: 'none', backgroundColor: '#555' }} />,
      disabled: true,
    },
    ...continents
      .map((continent) => ({ key: continent, text: continent }))
      .sort(({ key: a }, { key: b }) => (a < b ? -1 : 1)),
    {
      key: 'divider2',
      content: <hr style={{ height: 1, margin: 0, padding: 0, border: 'none', backgroundColor: '#555' }} />,
      disabled: true,
    },
    ...Object.keys(areas).map((area) => ({ key: area, text: area })),
    {
      key: 'divider3',
      content: <hr style={{ height: 1, margin: 0, padding: 0, border: 'none', backgroundColor: '#555' }} />,
      disabled: true,
    },
    ...Object.entries(list)
      .sort(([_b, { name: a }], [_a, { name: b }]) => (a < b ? -1 : 1))
      .map(([key, { name }]) => ({ key, text: name })),
  ];

  return (
    <div>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Header style={{ fontSize: 28, paddingBottom: 24 }}>
                    <img
                      src={`/flags/${id.toUpperCase()}.png`}
                      style={{ width: 42, marginTop: -4, marginRight: 4 }}
                      alt=""
                    />
                    {name} {!active && <span style={{ color: '#ccc', fontSize: 18 }}>(inactive)</span>}
                  </Header>
                </Grid.Column>
                <Grid.Column width={4} textAlign="right">
                  <Checkbox
                    checked={active}
                    toggle
                    style={{ marginRight: 12 }}
                    onChange={() => {
                      setActive(!active);
                      setIsEditing(true);
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Image src={`/country_hero/${id}.jpg`} alt="" fluid />
            <iframe
              src={`https://blog.wego.com/covid19-travel-restrictions-by-destination-country/#${id.toUpperCase()}`}
              sandbox=""
              style={{ width: '100%', height: 300, marginTop: 30 }}
            />
            <Header disabled={scrapers.length === 0}>
              {scrapers.length ? (
                <>
                  <span>Scrapers</span>
                  <Button
                    color="black"
                    circular
                    style={{ float: 'right', width: 32, height: 32, padding: 4 }}
                    icon="refresh"
                    loading={scraping}
                    disabled={scraping}
                    onClick={() => {
                      setScraping(true);
                      firebase
                        .functions()
                        .httpsCallable('scraper-callScraper')({ countries: [id], sendEmails: true })
                        .then(() => {
                          dispatch(fetchCountry(id));
                          setScraping(false);
                        });
                    }}
                  />
                </>
              ) : (
                'No scrapers added'
              )}
            </Header>
            {scrapers.map((scraper, index) => (
              <Card key={`scraper-${index}`} fluid style={{ maxHeight: 320, overflowY: 'scroll' }}>
                <Card.Content>
                  <Card.Header style={{ height: 40 }}>
                    {scraper.id}{' '}
                    {scraper.acknowledged !== undefined && (
                      <Button
                        color={scraper.acknowledged ? 'green' : 'grey'}
                        circular
                        style={{ float: 'right', width: 32, height: 32, padding: 4 }}
                        icon="checkmark"
                        loading={loadingScraperToggle === scraper.id}
                        disabled={loadingScraperToggle === scraper.id}
                        onClick={() => {
                          dispatch(toggleAcknowledgeScraper(scraper.id));
                        }}
                      />
                    )}
                  </Card.Header>
                  <Divider />
                  <a href={scraper.url} target="_blank" rel="noopener noreferrer">
                    {scraper.url}
                  </a>
                  <br />
                  <code>
                    {scraper.selector} [{scraper.index || 0}]
                  </code>
                  <Divider />
                  {Boolean(scraper.text) && (
                    <div dangerouslySetInnerHTML={{ __html: scraper.text.replace(/\s\s/g, '<br />') }} />
                  )}
                  <Divider />
                  <Card.Description>
                    {scraper.scraped && scraper.updated ? (
                      <Grid columns={2}>
                        <Grid.Row>
                          <Grid.Column>Last scraped: {moment(scraper.scraped.seconds * 1000).fromNow()}</Grid.Column>
                          <Grid.Column>Last updated: {moment(scraper.updated.seconds * 1000).fromNow()}</Grid.Column>
                        </Grid.Row>
                      </Grid>
                    ) : (
                      'Not scraped yet'
                    )}
                  </Card.Description>
                </Card.Content>
              </Card>
            ))}
            <Button
              fluid
              color="green"
              onClick={() => {
                dispatch(updateCountry(id, { active, restrictions }));
                setIsEditing(false);
              }}
              loading={isSaving}
              disabled={!isEditing}
            >
              <Icon name="save" />
              Save
            </Button>
          </Grid.Column>
          <Grid.Column width={8}>
            <Header>
              Travel Restrictions{' '}
              {Boolean(values.length) && <span style={{ color: '#aaa' }}>{`(${values.length})`}</span>}
              <Button
                style={{ float: 'right' }}
                color="green"
                onClick={() => {
                  dispatch(updateCountry(id, { active, restrictions }));
                  setIsEditing(false);
                }}
                loading={isSaving}
                disabled={!isEditing}
              >
                <Icon name="save" />
                Save
              </Button>
            </Header>
            <Grid columns={4} stackable style={{ margin: '0px 0px ' }}>
              <Grid.Row>
                {[
                  ['Unknown', 'question circle outline'],
                  ['Closed', 'ban'],
                  ['Restricted', 'warning'],
                  ['Open', 'thumbs up outline'],
                ].map(([status, icon], isStatus) => (
                  <Grid.Column style={{ padding: 3 }} key={status.toLowerCase()}>
                    <Button
                      fluid
                      color="black"
                      disabled={!values.length}
                      style={{ padding: 0 }}
                      onClick={() => {
                        const updatedRestrictions = isStatus
                          ? [
                              ...restrictions[status.toLowerCase()].filter((country) => !values.includes(country)),
                              ...values,
                            ]
                          : [];

                        setRestrictions(
                          Object.keys(restrictions).reduce(
                            (acc, next) => ({
                              ...acc,
                              [next]:
                                next === status.toLowerCase()
                                  ? updatedRestrictions
                                  : restrictions[next].filter((country) => !values.includes(country)),
                            }),
                            {}
                          )
                        );

                        setIsEditing(true);
                        setValues([]);
                      }}
                    >
                      <Icon name={icon} style={{ padding: 0, margin: 0 }} /> {status}
                    </Button>
                  </Grid.Column>
                ))}
              </Grid.Row>
            </Grid>
            <Dropdown
              clearable
              fluid
              multiple
              search
              selection
              options={countryOptions.map(({ key, text, disabled, content }) => ({
                key,
                value: key,
                text,
                disabled,
                content,
              }))}
              placeholder="Select Country"
              value={values}
              closeOnChange
              onChange={(_, { value }) => {
                if (value[value.length - 1] === 'all') {
                  setValues(
                    Object.entries(list)
                      .filter((country) => !values.includes(country) && country !== id)
                      .sort(([_b, { name: a }], [_a, { name: b }]) => (a < b ? -1 : 1))
                      .map(([key]) => key)
                      .filter((country) => country !== id)
                  );
                } else {
                  const isContinent = continents.reduce((acc, next) => acc || next === value[value.length - 1], false);
                  const isArea = Object.keys(areas).reduce(
                    (acc, next) => acc || next === value[value.length - 1],
                    false
                  );

                  if (isContinent) {
                    setValues([
                      ...value.slice(0, value.length - 1),
                      ...Object.entries(list)
                        .filter(([_, { continent }]) => continent && value[value.length - 1] === continent)
                        .map(([key]) => key)
                        .filter((country) => !values.includes(country) && country !== id),
                    ]);
                  } else if (isArea) {
                    setValues(
                      [
                        ...value.slice(0, value.length - 1),
                        ...areas[value[value.length - 1]]
                          .map(({ key }) => key)
                          .filter((country) => !values.includes(country) && country !== id),
                      ].filter((country) => country !== id)
                    );
                  } else {
                    setValues(value);
                  }
                }
              }}
            />
            <ComposableMap
              projection="geoMercator"
              width={1000}
              height={600}
              projectionConfig={{ scale: 180, center: [16, 20] }}
              style={{ marginTop: 24 }}
            >
              <Geographies geography={countries}>
                {({ geographies }) =>
                  geographies.map((geo) => {
                    let fill = '#e6e6e6';
                    const currentId = geo.properties.ISO_A2.toLowerCase();
                    if (currentId === id) {
                      fill = '#000';
                    } else if (restrictions.closed.includes(currentId)) {
                      fill = '#c22';
                    } else if (restrictions.restricted.includes(currentId)) {
                      fill = '#cc2';
                    } else if (restrictions.open.includes(currentId)) {
                      fill = '#2c2';
                    }

                    return (
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        stroke="#eee"
                        fill={fill}
                        border="#000000"
                        onClick={() => {
                          if (![...values, id].includes(geo.properties.ISO_A2.toLowerCase())) {
                            setValues([...values, geo.properties.ISO_A2.toLowerCase()]);
                          }
                        }}
                        style={{
                          hover: {
                            opacity: 0.8,
                            transition: 'all 250ms',
                            cursor: 'pointer',
                          },
                        }}
                      />
                    );
                  })
                }
              </Geographies>
            </ComposableMap>
            <div style={{ display: 'flex', marginTop: 8, marginBottom: 24 }}>
              <Input
                style={{ width: '100%', marginRight: 6 }}
                value={manualCountryList}
                onChange={(input) => setManualCountryList(input.target.value)}
              />
              <Button
                onClick={() => {
                  const manualCountries = manualCountryList
                    .split(',')
                    .map((country) =>
                      country
                        .trim()
                        .replace(/ *\([^)]*\) */g, '')
                        .trim()
                    )
                    .map((country) => (country === 'Czechia' ? 'Czech Republic' : country))
                    .map((country) => (country === 'Korea' ? 'South Korea' : country));

                  const manualValues = Object.entries(list).reduce((acc, [key, { name }]) => {
                    return manualCountries.includes(name.trim()) && !values.includes(key) ? [...acc, key] : acc;
                  }, []);

                  setValues([...values, ...manualValues]);
                  setManualCountryList('');
                }}
              >
                Copy
              </Button>
            </div>
            <Grid columns={3}>
              <Grid.Row>
                {['Closed', 'Restricted', 'Open'].map((status, index) => (
                  <Grid.Column textAlign="center" key={`country-restriction-status-${index}`}>
                    <Header>{status}</Header>
                    <List>
                      {restrictions[status.toLowerCase()]
                        .filter((country) => list[country])
                        .sort((a, b) => (list[a].name < list[b].name ? -1 : 1))
                        .map((country, index) => (
                          <ListItem key={`country-restriction-${status.toLowerCase()}-${index}`}>
                            {list[country].name}
                          </ListItem>
                        ))}
                    </List>
                  </Grid.Column>
                ))}
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Prompt when={isEditing} message="Are you sure you want to leave without saving?" />
    </div>
  );
};

export default CountryDetails;
