import { FETCH_ARTICLES } from '../types';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

export const fetchArticles = (language) => {
  return async (dispatch, getState) => {
    const articlesSnapshot = await firebase
      .firestore()
      .collection('articles')
      .where('isPublished', '==', true)
      .where('language', 'array-contains', language)
      .orderBy('published', 'desc')
      .get();

    const articles = articlesSnapshot.docs.map((doc) => {
      const data = doc.data();
      const header = data.header[language];
      const ingress = data.ingress[language];
      const blocks = data.blocks.map((block) => {
        if (block.type === 'image') {
          return {
            ...block,
            caption: block.caption[language],
          };
        } else {
          return {
            ...block,
            text: block.text[language],
          };
        }
      });

      return { id: doc.id, ...doc.data(), header, ingress, blocks };
    });

    dispatch({
      type: FETCH_ARTICLES,
      payload: articles,
    });
  };
};
