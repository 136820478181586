import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Segment, Grid, Header, Statistic, Icon, Image } from 'semantic-ui-react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import countries from '../../../countries.json';
import DashboardFeed from './DashboardFeed';
import moment from 'moment';
import './dashboard.scss';

import { fetchScraperUpdates, fetchUsers } from '../../../store/actions';

const Dashboard = () => {
  const [country, setCountry] = useState('');
  const [hoverCountry, setHoverCountry] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchScraperUpdates());
    dispatch(fetchUsers());
  }, [dispatch]);
  const {
    admin: { scraperUpdates, users },
    countries: { list },
  } = useSelector((state) => state);

  if (country) {
    return <Redirect to={`/admin/countries/${country}`} />;
  }

  console.log(users);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={10}>
          <Segment>
            <Statistic.Group widths={3}>
              <Statistic>
                <Statistic.Value>
                  <Icon name="user outline" size="small" /> {users.length}
                </Statistic.Value>
                <Statistic.Label>Members</Statistic.Label>
              </Statistic>

              <Statistic>
                <Statistic.Value>
                  <Icon name="gem outline" size="small" />{' '}
                  {users.filter((user) => user.expires && moment(user.expires.seconds * 1000).isAfter(moment())).length}
                </Statistic.Value>
                <Statistic.Label>Premium</Statistic.Label>
              </Statistic>

              <Statistic>
                <Statistic.Value>€ {users.length * 3}</Statistic.Value>
                <Statistic.Label>Monthly revenue</Statistic.Label>
              </Statistic>
            </Statistic.Group>
          </Segment>
          <Segment>
            <ComposableMap
              projection="geoMercator"
              width={1000}
              height={600}
              projectionConfig={{ scale: 180, center: [16, 20] }}
              style={{ marginTop: 24 }}
            >
              <Geographies geography={countries}>
                {({ geographies }) =>
                  geographies.map((geo) => {
                    return (
                      <Geography
                        onClick={() => setCountry(geo.properties.ISO_A2.toLowerCase())}
                        onMouseOver={() => setHoverCountry(geo.properties.ISO_A2.toLowerCase())}
                        onMouseOut={() => setHoverCountry('')}
                        key={geo.rsmKey}
                        geography={geo}
                        style={{
                          default: {
                            fill: scraperUpdates
                              .map(({ country }) => country)
                              .includes(geo.properties.ISO_A2.toLowerCase())
                              ? '#e29101'
                              : '#fff',
                            stroke: '#e4e4e4',
                            strokeWidth: 0.75,
                            outline: 'none',
                            transition: 'all 250ms',
                          },
                          hover: {
                            fill: '#f6a312',
                            stroke: '#f6a312',
                            strokeWidth: 0.75,
                            outline: 'none',
                            transition: 'all 250ms',
                            cursor: 'pointer',
                          },
                          pressed: {
                            fill: '#e69302',
                            stroke: '#f6a312',
                            strokeWidth: 0.75,
                            outline: 'none',
                            transition: 'all 250ms',
                            cursor: 'pointer',
                          },
                        }}
                      />
                    );
                  })
                }
              </Geographies>
            </ComposableMap>
            <Header
              size="large"
              style={{ height: 36, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              {list[hoverCountry] && (
                <>
                  <Image src={`/flags/${hoverCountry}.png`} size="small" alt="" /> {list[hoverCountry].name}
                </>
              )}
            </Header>
          </Segment>
        </Grid.Column>
        <Grid.Column width={6}>
          <Segment>
            <DashboardFeed />
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Dashboard;
