import React from 'react';

import AdminArticleList from './AdminArticleList';
import AdminArticleEditor from './AdminArticleEditor';

const AdminArticles = () => {
  return (
    <div style={{ display: 'flex' }}>
      <AdminArticleList />
      <AdminArticleEditor />
    </div>
  );
};

export default AdminArticles;
