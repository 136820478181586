import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useScrollYPosition } from 'react-use-scroll-position';
import Tippy from '@tippyjs/react';
import { motion } from 'framer-motion';

import Translate from '../utilities/Translate';

import './countryHeader.scss';

const CountryHeader = ({ country, flag, hero, lastUpdated, children, travelStatus, firstLoad }) => {
  const scrollY = useScrollYPosition();
  const [subscribed, setSubscribed] = useState(false);
  const {
    app: { departureCountry, destinationCountry },
    countries: { list },
  } = useSelector((state) => state);

  return (
    <div className="country-header-container">
      <div className="country-header-hero">
        <div className="country-header-hero-title-background">
          <div className="country-header-hero-title">
            <div className="country-header-hero-left">
              <Link to="/">
                <img className="country-header-hero-back-button" src="/back_button_black.png" alt="" />
              </Link>
              <h1>
                <Translate>{country}</Translate>
              </h1>
            </div>
            <div className="country-header-hero-right">
              <div className="country-header-hero-status">
                <span>
                  <Translate>
                    <Translate>{list[destinationCountry].name}</Translate> <Translate>is</Translate>{' '}
                    <Translate>{travelStatus}</Translate> <Translate>for</Translate>{' '}
                    <Translate>{list[departureCountry].name}</Translate>
                  </Translate>
                </span>
              </div>
              <div>
                <Tippy
                  content={
                    <span>
                      <Translate>{list[destinationCountry].name}</Translate> <Translate>is</Translate>{' '}
                      <Translate>{travelStatus}</Translate> <Translate>for</Translate>{' '}
                      <Translate>{list[departureCountry].name}</Translate>
                    </span>
                  }
                >
                  <img className="country-header-open-status" src={`/travel_status_${travelStatus}.png`} alt="" />
                </Tippy>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="country-header-content-container">
        <div className="country-header-content">{children}</div>
      </div>
    </div>
  );
};

export default CountryHeader;
