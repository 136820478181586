import React, { useEffect } from 'react';
import axios from 'axios';
import { Loader } from 'semantic-ui-react';
import { Switch, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';

import supportedLocales from './translations/supportedLocales';

import App from './components/App';
import Admin from './components/admin/Admin';

import {
  fetchUser,
  fetchCountries,
  fetchCountryUpdates,
  setLanguage,
  signIn,
  setCookieConsent,
  setFirebaseInitialized,
  setDepartureCountry,
} from './store/actions';

const Router = () => {
  const dispatch = useDispatch();
  const {
    app: { hasSeenCookieConsentModal, cookieConsent },
  } = useSelector((state) => state);

  useEffect(() => {
    if (localStorage.getItem('hasSeenCookieConsentModal') === 'true') {
      dispatch(
        setCookieConsent({
          necessary: localStorage.getItem('necessary') === 'true',
          analytics: localStorage.getItem('analytics') === 'true',
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (!hasSeenCookieConsentModal) {
      return;
    }

    firebase.initializeApp({
      apiKey: 'AIzaSyCsJjtDnU3w5PUJyc9D9mxglw0z-7Jn1VE',
      authDomain: 'update-travel.firebaseapp.com',
      databaseURL: 'https://update-travel.firebaseio.com',
      projectId: 'update-travel',
      storageBucket: 'update-travel.appspot.com',
      messagingSenderId: '715069128198',
      appId: '1:715069128198:web:fb849969730bea166c0d87',
      measurementId: 'G-1CZNTDSYV3',
    });

    dispatch(setFirebaseInitialized());

    if (cookieConsent.analytics) {
      firebase.analytics();
    }
    dispatch(fetchCountries());
    dispatch(fetchCountryUpdates());

    const localStorageLanguage = localStorage.getItem('language');
    const browserLanguage = window.navigator.language.slice(0, 2);

    if (localStorageLanguage && supportedLocales.includes(localStorageLanguage)) {
      dispatch(setLanguage(localStorageLanguage));
    } else if (supportedLocales.includes(browserLanguage)) {
      dispatch(setLanguage(browserLanguage));
    }

    const localStorageCountry = localStorage.getItem('country');

    if (localStorageCountry) {
      dispatch(setDepartureCountry(localStorageCountry));
    } else {
      axios
        .get('https://us-central1-update-travel.cloudfunctions.net/app-getUserCountry')
        .then(({ data: { country } }) => {
          dispatch(setDepartureCountry(country));
        })
        .catch(() => {
          dispatch(setDepartureCountry('us'));
        });
    }

    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }
      dispatch(signIn(email));
    }

    return firebase.auth().onAuthStateChanged((user) => {
      dispatch(fetchUser(user ? user.uid : false));
    });
  }, [hasSeenCookieConsentModal, cookieConsent, dispatch]);

  return (
    <Switch>
      <Route path="/admin" component={Admin} />
      <Route path="/" component={App} />
    </Switch>
  );
};

export default Router;
