import { useSelector } from 'react-redux';

const Translate = ({ children, params }) => {
  const { active, translations } = useSelector((state) => state.locale);

  if (translations[active][children] || translations[active].countries[children]) {
    let translation = translations[active][children] || translations[active].countries[children];

    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        const regex = new RegExp(`[[${key}]]`, 'g');
        translation = translation.replace(regex, value);
      });
    }

    return translation;
  }

  return children;
};

export default Translate;
