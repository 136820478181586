import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, Input, Label, Divider, Header } from 'semantic-ui-react';
import { Link, useParams } from 'react-router-dom';

import CountryDetails from './CountryDetails';

import { fetchCountries, fetchScraperUpdates } from '../../../store/actions';

const CountryList = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCountries());
    dispatch(fetchScraperUpdates());
  }, [dispatch]);
  const {
    admin: { scraperUpdates },
    countries: { list: countries },
  } = useSelector((state) => state);

  const { country } = useParams();
  const [search, setSearch] = useState('');

  const active = Object.entries(countries)
    .filter(([_, { active }]) => active)
    .filter(([_, { name }]) => name.toLowerCase().includes(search.toLowerCase()))
    .sort((a, b) => (a[1]['name'] < b[1]['name'] ? -1 : 1));

  const inactive = Object.entries(countries)
    .filter(([_, { active }]) => !active)
    .filter(([_, { name }]) => name.toLowerCase().includes(search.toLowerCase()))
    .sort((a, b) => (a[1]['name'] < b[1]['name'] ? -1 : 1));

  const scrapers = scraperUpdates.reduce(
    (acc, { acknowledged, country }) =>
      acknowledged ? acc : acc[country] ? { ...acc, [country]: acc[country] + 1 } : { ...acc, [country]: 1 },
    {}
  );

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <Menu pointing secondary vertical>
          <Input
            icon="search"
            value={search}
            onChange={(input) => setSearch(input.target.value)}
            placeholder="Search country..."
            style={{ marginBottom: 24, marginRight: 12 }}
          />
          <Header style={{ margin: 2 }}>Inactive: {inactive.length}</Header>
          {inactive.map(([key, { name }]) => (
            <Menu.Item key={key} disabled as={Link} active={key === country} to={`/admin/countries/${key}`}>
              {Boolean(scrapers[key]) && <Label color="red">{scrapers[key]}</Label>}
              {name}
            </Menu.Item>
          ))}
          <Divider />
          <Header style={{ margin: 0 }}>Active: {active.length}</Header>
          {active.map(([key, { name }]) => (
            <Menu.Item key={key} as={Link} active={key === country} to={`/admin/countries/${key}`} name="inbox">
              {Boolean(scrapers[key]) && <Label color="red">{scrapers[key]}</Label>}
              {name}
            </Menu.Item>
          ))}
        </Menu>
        <div style={{ width: '100%', marginLeft: 24 }}>{Boolean(country) && <CountryDetails id={country} />}</div>
      </div>
    </div>
  );
};

export default CountryList;
